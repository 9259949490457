import moment from 'moment';
import './ProductivityCalendar.scss';
import styles from './ProductivityCalendar.module.css'
import React, {useEffect, useState} from "react";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from "@mui/material/IconButton";
import {Tooltip} from "@mui/material";
import ModalInfo from "./ModalInfo/ModalInfo";

const DayNames = {
    1: 'Mon',
    3: 'Wed',
    5: 'Fri'
}

function Square({ color, id, date, data, index, MomentDateDispatch }) {
    let style = {
        backgroundColor: color
    };
    //console.log(date._d)
    let test = `${date._d}`.substr(0, 15)
    //data.map(el => console.log(el.id === index))
    return (
        <Tooltip title={test} >
            <div id={date}

                 onClick={() => MomentDateDispatch(index)}
                 className='timeline-squares-square'
                 style={style}></div>
        </Tooltip>

    )
}

function Month({ startDate, index }) {
    let date = moment(startDate).add(index * 7, 'day');
    let monthName = date.format('MMM');
    //console.log(date)

    return (
        <div className={`timeline-months-month ${monthName}`}>
            {monthName}
        </div>
    )
}

function WeekDay({ index }) {
    return (
        <div className='timeline-weekdays-weekday'>
            {DayNames[index]}
        </div>
    )
}

function Timeline({ range, data, MomentDateDispatch}) {
    let days = Math.abs(range[0].diff(range[1], 'days'));
    let squares = Array.from(new Array(days));
    let weekDays = Array.from(new Array(7));
    //console.log(weekDays)
    let months = Array.from(new Array(Math.floor(days / 7)));

    let min = Math.min(0, ...data.map(d => d.value));
    let max = Math.max(...data.map(d => d.value));

    let colorMultiplier = 1 / (max - min);
    let startDate = range[0];
    const DayFormat = 'DDMMYYYY';

    return (
        <div className='timeline'>
            <div className={styles.Title}>Календарь продуктивности
                <ModalInfo/>
            </div>
            <div className="timeline-months">
                {months.map((_, index) => <Month key={index} index={index} startDate={startDate} />)}
            </div>

            <div className="timeline-body">

                <div className="timeline-weekdays">
                    {weekDays.map((_, index) => <WeekDay key={index} index={index} startDate={startDate} />)}
                </div>

                <div className="timeline-squares">
                    {squares.map((_, index) => {
                        let date = moment(startDate).add(index, 'day');
                        let dataPoint = data.find(d => moment(date).format(DayFormat) === moment(d.date).format(DayFormat));
                        let color  = () => {
                            if (dataPoint) {
                                if (dataPoint.value > 0 && dataPoint.value <= 1) {
                                    return '#7bd9d6'
                                } else if (dataPoint.value > 1 && dataPoint.value <= 2) {
                                    return '#62bbb8'
                                } else if (dataPoint.value > 2 && dataPoint.value <= 3) {
                                    return '#3f8986'
                                } else if (dataPoint.value > 2 && dataPoint.value <= 3) {
                                    return '#255c5a'
                                } else if (dataPoint.value > 3 ) {
                                    return '#972175'
                                }
                            }

                        }

                        return (
                            <Square
                                id={index}
                                key={index}
                                index={index}
                                date={date}
                                data={data}
                                color={color()}
                                MomentDateDispatch={MomentDateDispatch}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function ProductivityCalendar(props) {
    // 1 year range
    let startDate = moment().add(-365, 'days');
    let dateRange = [startDate, moment()];
    //const [dataArray, setDataArray] = useState([]);

    const data = Array.from(new Array(365)).map((_, index) => {
        return {
            date: moment(startDate).add(index, 'day'),
            //value: Math.floor(Math.random() * 4),
            value: 0,
            id: index
        };

    });
    useEffect(() => {
        props.SetDataDispatch(data)
    },[])
    //console.log(props.productivityState.data)
    //data[0].value = 53

    //let dataPoint = data.find(d => moment(date).format(DayFormat) === moment(d.date).format(DayFormat));
    //console.log(dataArray[1])
    //console.log(data)

    return (
        <>
            <Timeline MomentDateDispatch={props.MomentDateDispatch} range={dateRange} data={props.productivityState.data}  />
            </>
    )
}

export default ProductivityCalendar;
