import React, {useEffect, useState} from 'react';
import {Link, NavLink, Route} from "react-router-dom";
import styles from './InfoTasks.module.css'
import {connect} from "react-redux";
import {Box, Button, IconButton, Modal, Snackbar, Typography} from "@mui/material";
import ModalAddTask from "../ModalAddTask/ModalAddTask";
import {
    AddDayTaskCreator,
    AddTaskCreator,
    ChangeDayStatusCreator, ChangeDayTimeIsOverCreator,
    ChangeStatusCreator, DeleteDayTaskCreator,
    IsActiveCreator
} from "../../../store/tasks-reducer";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    deleteDayTask,
    fetchAllDayTasks,
    fetchAllMonthTasks,
    fetchAllWeekTasks,
    fetchAllYearTasks
} from "../../../http/taskApi";
import Cookies from "js-cookie";

let mapStateToProps = (state) => {
    return {
        tasksState: state.tasksState,
        userState: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddDayTaskDispatch: (task) => {
            dispatch(AddDayTaskCreator(task))
        }
    }
}

const InfoTasks = (props) => {
    let dayActiveTasks = 0;
    let weekActiveTasks = 0;
    let monthActiveTasks = 0;
    let yearActiveTasks = 0;
    props.tasksState.dayTasks.map(task => {
        if (task.status === true && task.timeIsOver === false) {
            return dayActiveTasks += 1
        }
    })
    props.tasksState.weekTasks.map(task => {
        if (task.status === true && task.timeIsOver === false) {
            return weekActiveTasks += 1
        }
    })
    props.tasksState.monthTasks.map(task => {
        if (task.status === true && task.timeIsOver === false) {
            return monthActiveTasks += 1
        }
    })
    props.tasksState.yearTasks.map(task => {
        if (task.status === true && task.timeIsOver === false) {
            return yearActiveTasks += 1
        }
    })


    return (
        <div className={styles.TasksInfoWrapper}>
            <div className={styles.Title}>Активные задачи</div>
            <div className={styles.TasksInfoBlockWrapper}>
                <div className={styles.TasksItemInfoBlockWrapper}>
                    <div className={styles.TasksItemTitle}>День</div>
                    <div className={styles.TasksItemNum}>{dayActiveTasks}</div>
                </div>
                <div className={styles.TasksItemInfoBlockWrapper}>
                    <div className={styles.TasksItemTitle}>Неделя</div>
                    <div className={styles.TasksItemNum}>{weekActiveTasks}</div>
                </div>
                <div className={styles.TasksItemInfoBlockWrapper}>
                    <div className={styles.TasksItemTitle}>Месяц</div>
                    <div className={styles.TasksItemNum}>{monthActiveTasks}</div>
                </div>
                <div className={styles.TasksItemInfoBlockWrapper}>
                    <div className={styles.TasksItemTitle}>Год</div>
                    <div className={styles.TasksItemNum}>{yearActiveTasks}</div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTasks);