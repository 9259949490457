import React from "react";
import styles from './Footer.module.css'
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

const Footer = () => {

    let navigate = useNavigate();

    return (
        <div className={styles.Wrapper}>
            <div className={styles.FooterLinkBlock}>
                <div className={styles.About} onClick={() => navigate('/contacts')}>Связаться с нами</div>
                <div className={styles.About} onClick={() => navigate('/privacy-policy')}>Политика конфиденциальности</div>
                <div className={styles.About} onClick={() => navigate('/about')}>О проекте</div>

            </div>
                <p className={styles.TitleFooter}>© 2024 Biz Machine</p>
            {/*<Link className={styles.Privacy} to="/privacy-policy">Политика Конфиденциальности</Link>*/}
        </div>
    )
}

export default Footer;