import React, {useEffect, useState} from 'react';
import {Link, NavLink, Route} from "react-router-dom";
import styles from './YearTasks.module.css'
import {connect} from "react-redux";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import ModalAddTask from "../ModalAddTask/ModalAddTask";
import {
    AddYearTaskCreator,
    AddTaskCreator,
    ChangeYearStatusCreator, ChangeYearTimeIsOverCreator,
    ChangeStatusCreator, DeleteYearTaskCreator,
    IsActiveCreator
} from "../../../store/tasks-reducer";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {deleteYearTask, fetchAllYearTasks} from "../../../http/taskApi";
import YearTaskItem from "./YearTaskItem";

let mapStateToProps = (state) => {
    return {
        state: state
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddYearTaskDispatch: (task) => {
            dispatch(AddYearTaskCreator(task))
        }, ChangeYearStatusDispatch: (id) => {
            dispatch(ChangeYearStatusCreator(id))
        }, ChangeYearTimeIsOverDispatch: (id) => {
            dispatch(ChangeYearTimeIsOverCreator(id))
        }, DeleteYearTaskDispatch: (id) => {
            dispatch(DeleteYearTaskCreator(id))
        }
    }
}

const YearTasks = (props) => {

    const Delete = async (taskId) => {
        await deleteYearTask(taskId, props.state.userState.user.id)//.then((data) => console.log('Delete taskId ' + taskId + ' User id ' + props.state.userState.user.id))
        await fetchAllYearTasks(props.state.userState.user.id).then((data) => props.AddYearTaskDispatch(data))
    }

    return (
        <div className={styles.YearTasksMainWrapper}>
            <div className={styles.YearTasksBlockWrapper}>
                <div className={styles.YearTaskTitleWrapper}>
                    <ModalAddTask
                        AddTaskDispatch={props.AddYearTaskDispatch}
                        name='year'
                        state={props.state}/>
                </div>
                <div>{props.state.tasksState.yearTasks.map(el =>
                    <YearTaskItem key={el.id}
                                 status={el.status}
                                 ChangeYearStatusDispatch={props.ChangeYearStatusDispatch}
                                 ChangeYearTimeIsOverDispatch={props.ChangeYearTimeIsOverDispatch}
                                 DeleteYearTaskDispatch={props.DeleteYearTaskDispatch}
                                 AddYearTaskDispatch={props.AddYearTaskDispatch}
                                 Delete={Delete}
                                 id={el.id}
                                 task={el.task}
                                 startDate={el.startDate}
                                 timeIsOver={el.timeIsOver}
                                 userId={props.state.userState.user.id}
                    />
                )}
                </div>

            </div>



        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(YearTasks);