import React, {useEffect, useState} from 'react';
import styles from './Account.module.css'
import Password from "./Password/Password";
import {Navigate} from "react-router-dom";
import TasksStat from "./TasksStat/TasksStat";
import {check, fetchSessions} from "../../http/userApi";
import {fetchAllUserStat} from "../../http/statApi";
import Sessions from "./Sessions/Sessions";
import Email from "./Email/Email";
import DeleteAccount from "./DeleteAccount/DeleteAccount";

const Account = (props) => {

    if (props.userState.isAuth === false) {
        return <Navigate to="/" replace={true} />
    }

    


    return (
        <div className={styles.Wrapper}>
                <div className={styles.Title}>Данные аккаунта</div>

                {/*<TasksStat userState={props.userState}
                           statisticsState={props.statisticsState}
                           AddStatDispatch={props.AddStatDispatch}
                />*/}
{/**/}
                <Email state={props.userState.user} AddUserDispatch={props.AddUserDispatch}/>
                <Sessions userState={props.userState} AddSessionsDispatch={props.AddSessionsDispatch}/>
                {/*<Button>Выслать письмо с подвтверждением</Button>*/}
                {/*{(changePasswordFlag === false) ?
                    <Button onClick={() => setChangePasswordFlag(true)}>Сменить пароль</Button>
                    : <Password state={props.userState.user}/>}*/}
                <Password state={props.userState.user}/>
                {/*<DeleteAccount state={props.userState.user}/>*/}

        </div>
    );
};

export default Account;