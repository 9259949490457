import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import styles from './ProjectProfile.module.css'
import {fetchAllProjects} from "../../../http/projectApi";
import ModalAddTaskInProject from "./ProjectProfileListTask/ModalAddTaskInProject/ModalAddTaskInProject";
import ListTask from "./ProjectProfileListTask/ListTask";
import ListInProgress from "./ProjectProfileListInProgress/ListInProgress";
import {AddOneTaskInProjectCreator} from "../../../store/projects-reducer";
import ListDone from "./ProjectProfileListDone/ListDone";

const ProjectProfile = (props) => {
    let navigate = useNavigate();
    const params = useParams()
    const [project, setProject] = React.useState({});

    useEffect(() => {
        fetchAllProjects(props.userState.user.id).then((data) => props.AddProjectsDispatch(data))
    }, [props.projectsState.update])

    //console.log(props.projectsState)


    useEffect(() => {
        if (props.projectsState.projects.length > 0) {
            props.projectsState.projects.map(project => {
                if (project.id === Number(Object.values(params)[0])) {
                    //console.log(project)
                    return setProject(project)
                }
            })
        }
    }, [props.projectsState.projects])

    //console.log(project)

    return (
        <div className={styles.Wrapper}>
            {/*<div>{project.name}</div>*/}
            <div className={styles.WrapperBlock}>

                <div className={styles.Title}>Очередь задач</div>

                <ModalAddTaskInProject project={project}
                                       EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
                                       AddOneTaskInProjectDispatch={props.AddOneTaskInProjectDispatch}
                                       AddDispatchTaskInProject={props.AddDispatchTaskInProject}/>
                <ListTask EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
                          AddDispatchTaskInProject={props.AddDispatchTaskInProject}
                          project={project}/>

            </div>
            <div className={styles.WrapperBlock}>

                <div className={styles.Title}>В процессе</div>

                <ListInProgress EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
                                AddDispatchTaskInProject={props.AddDispatchTaskInProject}
                                project={project}/>

            </div>
            <div className={styles.WrapperBlock}>
                <div className={styles.Title}>Выполненные</div>

                <ListDone EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
                          AddDispatchTaskInProject={props.AddDispatchTaskInProject}
                          project={project}/>

            </div>
        </div>
    );
};

export default ProjectProfile;