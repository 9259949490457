import React, {useState} from 'react';
import {Link, NavLink, Route} from "react-router-dom";
import styles from './SubNav.module.css'
import {connect} from "react-redux";
import {IsActiveCreator} from "../../store/tasks-reducer";
import Footer from "../Footer/Footer";

let mapStateToProps = (state) => {
    return {
        state: state
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        IsActiveDispatch: (data) => {
            dispatch(IsActiveCreator(data))
        }
    }
}

const SubNav = (props) => {

    const [isActive, setIsActive] = useState();

    return (
        <div className={styles.MainWrapper}>
            <div className={styles.TasksLinkBlock}>
                <NavLink className={styles.LinkWrapper}
                         style={({ isActive }) => (isActive ? {backgroundColor: '#78b6af', color: '#ffffff', textAlign: "center"} : {backgroundColor: '#ffffff',textAlign: "center"})}
                         to='/tasks'>
                    Задачи
                </NavLink>
            <NavLink className={styles.LinkWrapper}
                 style={({ isActive }) => (isActive ? {backgroundColor: '#78b6af', color: '#ffffff', textAlign: "center"} : {backgroundColor: '#ffffff',textAlign: "center"})}
                 to='/projects'
                >
                 Проекты <b>[In Dev]</b>
            </NavLink>
                {/*<NavLink className={styles.LinkWrapper}
                         style={({ isActive }) => (isActive ? {backgroundColor: '#78b6af', color: '#ffffff', textAlign: "center"} : {backgroundColor: '#ffffff',textAlign: "center"})}
                         to='/productivityCalendar'
                >
                    Календарь продуктивности
                </NavLink>*/}
            </div>
            <div className={styles.FooterWrapper}><Footer/></div>

        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubNav);