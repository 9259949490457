import React from 'react';
import styles from "./ProjectListItem.module.css"
import {useNavigate} from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@mui/material';
import ModalAddListItem from './ModalAddListItem/ModalAddListItem';


const ProjectListItem = (props) => {
    let navigate = useNavigate();

    //console.log(props.project.id)

    return (
                <div  onClick={ () =>  navigate('/projects' + '/' + props.project.id)} 
                className={styles.ProjectElementWrapper}>
                
                    <div className={styles.Title}>{props.project.name}</div>

                <div className={styles.ModalWrapper} onClick={(event) => event.stopPropagation()}>

                    <ModalAddListItem 
                    projectId={props.project.id}
                    AddProjectsDispatch={props.AddProjectsDispatch}
                userId={props.userId}/>
                    
                </div>                
                    
                </div>
    );
};

export default ProjectListItem;