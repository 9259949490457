import React, {useState} from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from '../Auth.module.css'
import {Alert, Button, Checkbox, CircularProgress, Snackbar} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {login, registration} from "../../../http/userApi";
import {CheckEmailBeforeSendingOnServer, CheckPasswordBeforeSendingOnServer} from "../../../Engines/service";




const Registration = (props) => {
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [checkbox, setCheckbox] = useState(false);

    const [tagResponse, setTagResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const [emailInputStyles, setEmailInputStyles] = useState(null);
    const [passwordInputStyles, setPasswordInputStyles] = useState(null);
    const [checkboxInputStyles, setCheckboxInputStyles] = useState(null);

    let errors = []

    const click = async () => {

        setEmailInputStyles(null)
        setPasswordInputStyles(null)
        
        setLoading(true)
        errors = []
        let data;
        try {
            // Проверка, что чекбокс проставлен
            if (checkbox === false) {
                setCheckboxInputStyles({color: "#ce6363"})
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Подтвердите соглашение</div>)
            }
           // Проверка, что пароли совпадают
            if (password !== password2) {
                setPasswordInputStyles({borderColor: "#ce6363"})
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Пароли не совпадают</div>)
            }

            // Проверка, что в поле строка
            if (typeof email !== typeof '' && typeof password !== typeof '' && typeof password2 !== typeof '') {
            
                setPasswordInputStyles({borderColor: "#ce6363"})
                setEmailInputStyles({borderColor: "#ce6363"})
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Поле не является строкой</div>)
            }

            // Проверка, что пароль и почта соответствуют требованиям
        
            if (CheckEmailBeforeSendingOnServer(email) === false) {
                setEmailInputStyles({borderColor: "#ce6363"})
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Почта не соответствует требованиям</div>)
            }
            if (CheckPasswordBeforeSendingOnServer(password) === false) {
                setPasswordInputStyles({borderColor: "#ce6363"})
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Пароль не соответствует требованиям</div>)
            }

          // Проверка на то, что на клиенте нет ошибок и можно делать запрос на сервер
            if (errors.length === 0) {

                data = await registration(email, password).finally( () => setLoading(false))
                // Проверка на то, что с сервера пришли данные
                if (data !== null && data !== undefined) {
                    props.AddUserDispatch(data)
                    props.IsAuthDispatch(true)
                    setLoading(false)
                    return navigate("/tasks")
                } else {
                    errors.push(<div ><label style={{fontWeight: 900, }}>Server Error:</label> &nbsp;&nbsp;Пришли неверные данные с сервера</div>)
                }
            }
               // setLoading(false)
        } catch (e) {
            //console.log(e)
            errors.push(<div ><label style={{fontWeight: 900, }}>Server Error:</label> 
            &nbsp;&nbsp;
            {(e.response !== undefined) ? "Status " + e.response.status : null} 
            {(e.response !== undefined) ? e.response.data.message : null} 
            {(e.message === 'timeout of 5000ms exceeded') ? "Время ожидания ответа от сервера истекло" : null}</div>)
        }


        setLoading(false)
        return  setTagResponse(
            <div className={styles.ErrorInfoWrapper}>
                {errors.map((value, i) => <div className={styles.ErrorInfoElement} key={i}><ErrorIcon style={{fontSize: '25px',  marginRight: '10px', }}/>{value}</div>)}
            </div>)
    }



    return (
        <div className={styles.Wrapper}>
            <div className={styles.AuthBlockWrapper}>
                
                <h2 style={{color: '#2c6866'}}>Регистрация</h2>

                {/* <div className={styles.QuoteWrapper}>
                <div className={styles.Quote}>путь в тысячу вёрст начинается с первого шага</div>
                </div> */}

                {tagResponse}

                <label>
                    <input className={styles.InputAuth}
                            style={emailInputStyles}
                           placeholder='Введите email'
                           value={email}
                           maxLength={64}
                           onChange={(e) => setEmail(e.target.value)}
                    />

                </label>
                <label>
                    <input className={styles.InputAuth}
                            style={passwordInputStyles}
                           placeholder='Введите пароль'
                           type='password'
                           maxLength={64}
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                    />

                    <div style={{color: 'rgba(44,104,102,0.74)'}}>До 64 символов</div>
                </label>
                <label>
                    <input className={styles.InputAuth}
                           style={passwordInputStyles}
                           placeholder='Введите пароль повторно'
                           type='password'
                           maxLength={64}
                           value={password2}
                           onChange={(e) => setPassword2(e.target.value)}
                    />

                </label>


                <div className={styles.PrivacyLinkWrapper}>
                    <Checkbox sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                             style={checkboxInputStyles}
                              onChange={e => setCheckbox(!checkbox)}
                              value={checkbox}
                    />
                    <div>Регистрируясь вы соглашаетесь c
                        <Link className={styles.PrivacyLink} to="/privacy-policy"> политикой обработки
                            персональных
                            данных </Link>
                        и с
                        <Link className={styles.PrivacyLink} to="/beta"> условиями участия в тестировании</Link>
                    </div>
                </div>

                <button
                    className={styles.ButtonAuth}
                    onClick={click}
                >
                    {(loading === false) ? 'Зарегистрироваться' :
                        <CircularProgress style={{color: '#ffffff',}} size="2.5rem" fontSize='small'/>}
                </button>

                <div style={{color: '#2c6866', marginTop: '5px', marginBottom: '15px'}}>
                    <NavLink className={styles.regText} to='/recovery'>Восстановление доступа</NavLink>
                </div>


                <div style={{color: '#2c6866'}}>
                    Есть аккаунт? <NavLink className={styles.regText} to='/login'>Войдите!</NavLink>
                </div>
            </div>
        </div>
    );
};

export default Registration;