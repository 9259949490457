import React from 'react';
import styles from "./ModalAddListItem.module.css"
import {Box, Button, IconButton, Input, Modal, TextareaAutosize, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deleteProjectApi, fetchAllProjects } from '../../../../../http/projectApi';

const ModalAddListItem = (props) => {
    let navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = React.useState(false);
    const [projectName, setProjectName] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //console.log(props)

    const SaveProject  = async ()  => {
        setOpen(true)
    }


    const DeleteProject = async () => {

           await deleteProjectApi(props.projectId)//.then(data => console.log(data))
           await fetchAllProjects(props.userId).then((data) => props.AddProjectsDispatch(data))
        
    }

    
   

    return (


            <div className={styles.Wrapper}>
                        <IconButton>
                            <DeleteForeverIcon className={styles.DeleteButton} onClick={() => SaveProject()}/>
                        </IconButton>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className={styles.ModalBoxWrapper}>
                        <div className={styles.ModalTitle}>Вы уверены что хотите удалить проект?</div>

                        <div className={styles.ButtonsWrapper}>
                        <button className={styles.Button} onClick={() => DeleteProject()}>Да</button>
                        <button className={styles.Button} onClick={() => handleClose()}>Нет</button>
                        </div>

                        </div>
                    </Box>
                </Modal>
            </div>

    );
};

export default ModalAddListItem;