import React, {useEffect, useState} from 'react';
import SubNav from "../SubNav/SubNav";
import styles from "./Tasks.module.css"
import {Navigate, Outlet, useNavigate} from "react-router-dom";
import TaskNav from "./TaskNav/TaskNav";
import {fetchAllDayTasks, fetchAllMonthTasks, fetchAllWeekTasks, fetchAllYearTasks} from "../../http/taskApi";
import {Snackbar} from "@mui/material";
import Cookies from "js-cookie";

const Tasks = (props) => {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);


   /* const Speak = text => {
        const speech = new SpeechSynthesisUtterance();
        speech.lang = "en";
        speech.text = text;
        speechSynthesis.speak(speech);
        speech.voice = "Microsoft David - English (United States)";
        const voices = speech.getVoices();
        console.log(voices)
            //.find((voice) => voice.name === "Microsoft David - English (United States)");

    };

    Speak("hello")*/

    /*let utterance = new SpeechSynthesisUtterance("Привет");
    utterance.voice = {voiceURI: "Google русский", name: "Google русский", lang: "ru-RU", localService: false, default: false}
    //console.log(speechSynthesis.getVoices())
    speechSynthesis.speak(utterance);
console.log(utterance)*/



    useEffect(()  => {
        //console.log(props.userState)
        if (props.userState.user.id !== undefined) {
            fetchAllDayTasks(props.userState.user.id).then((data) => props.AddDayTaskDispatch(data))
            fetchAllWeekTasks(props.userState.user.id).then((data) => props.AddWeekTaskDispatch(data))
            fetchAllMonthTasks(props.userState.user.id).then((data) => props.AddMonthTaskDispatch(data))
            fetchAllYearTasks(props.userState.user.id).then((data) => props.AddYearTaskDispatch(data))
            .finally(() => setLoading(false))
        }
    },[])
    //},[props.userState.user.id])

    if (loading) {
        return <div className='Spinner'></div>
    }
    return (
        <div className={styles.Wrapper}>
            <div className={styles.WrapperTaskNav}>

                <TaskNav/>
            </div>
            <div className={styles.MainBlock}>
                <div className={styles.WrapperTasks}>
                    <Outlet/>

                </div>

                {/*<div className={styles.WrapperStat}>
                    Graphics
                </div>*/}
            </div>

        </div>
    )
}

export default Tasks;


