import React from 'react';
import styles from "./ProjectList.module.css"
import ProjectListItem from "./ProjectListItem/ProjectListItem";

const ProjectsList = (props) => {
    return (
        <div className={styles.Wrapper}>
            {props.projectsState.projects.map(project =>
                <ProjectListItem 
                key={project.id} 
                project={project} 
                AddProjectsDispatch={props.AddProjectsDispatch}
                userId={props.userId}/>
            )}
        </div>
    );
};

export default ProjectsList;