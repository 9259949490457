import React from 'react';
import {Route, Routes,} from 'react-router-dom';

import {connect} from "react-redux";
import TasksContainer from "./Tasks/TasksContainer";
import DayTasks from "./Tasks/DayTasks/DayTasks";
import MonthTasks from "./Tasks/MonthTasks/MonthTasks";
import YearTasks from "./Tasks/YearTasks/YearTasks";
import Agreement from "./Agreement/Agreement";
import AccountContainer from "./Account/AccountContainer";
import WeekTasks from "./Tasks/WeekTasks/WeekTasks";
import About from "./About/About";
import Contacts from "./Contacts/Contacts";
import UnknownPage from "./UnknownPage/UnknownPage";
import StatisticsContainer from "./Statistics/StatisticsContainer";
import ProjectsContainer from "./Projects/ProjectsContainer";
import ProjectProfileContainer from "./Projects/ProjectProfile/ProjectProfileContainer";
import InfoTasks from "./Tasks/InfoTasks/InfoTasks";
import AgreementBeta from "./AgreementBeta/AgreementBeta";
import HomeContainer from "./Home/HomeContainer";
import ProductivityCalendar from "./ProductivityCalendar/ProductivityCalendar";
import ProductivityCalendarContainer from "./ProductivityCalendar/ProductivityCalendarContainer";


let mapStateToProps = (state) => {
    return {
        user: state.userState
    }
}

const MainRouter = (props) => {

    return (
        <Routes>
            <Route path='/' element={<HomeContainer/>} />
            <Route path="/tasks" element={<TasksContainer/>} >
                <Route index element={<InfoTasks />} />
                <Route path="day" element={<DayTasks />} />
                <Route path="week" element={<WeekTasks />} />
                <Route path="month" element={<MonthTasks />} />
                <Route path="year" element={<YearTasks />} />
            </Route>
            <Route path='/projects' element={<ProjectsContainer/>} />
            <Route path='/projects/*' element={<ProjectProfileContainer/>} />
            <Route path='/about' element={<About/>} />
            {/*<Route path='/productivityCalendar' element={<ProductivityCalendarContainer/>} />*/}
            <Route path='*' element={<UnknownPage/>} />
            <Route path='/contacts' element={<Contacts/>} />
            {/*<Route path='/logout' element={<LogoutContainer/>} />*/}
            <Route path='/account' element={<AccountContainer/>} />
            {/*<Route path='/statistics' element={<StatisticsContainer/>} />*/}
            <Route path='/privacy-policy'  element={<Agreement/>} />
            <Route path='/beta' element={<AgreementBeta/>} />
        </Routes>
    )
}

export default connect(mapStateToProps)(MainRouter);