import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import styles from './Projects.module.css'
import ModalAddProject from "./ModalAddProjects/ModalAddProjects";
import ProjectsList from "./ProjectList/ProjectsList";
import {fetchAllDayTasks, fetchAllMonthTasks, fetchAllWeekTasks, fetchAllYearTasks} from "../../http/taskApi";
import {fetchAllProjects} from "../../http/projectApi";




const Projects = (props) => {
    let navigate = useNavigate();

    useEffect(()  => {
        //console.log(props.userState)
        fetchAllProjects(props.userState.user.id).then((data) => props.AddProjectsDispatch(data))
    },[])

    //console.log(props.projectsState)
    return (
        <div className={styles.Wrapper}>
            <div className={styles.BlockTitleWrapper}>
                <div className={styles.Title}>Проекты</div>
                <div >
                    <ModalAddProject
                    AddProjectDispatch={props.AddProjectDispatch}
                    projectsState={props.projectsState}
                    userState={props.userState}
                />
                </div>
            </div>

                <ProjectsList 
                AddProjectsDispatch={props.AddProjectsDispatch}
                userId={props.userState.user.id}
                projectsState={props.projectsState}/>
        </div>
    );
};

export default Projects;