import React from 'react';
import styles from "./ModalInfo.module.css"
import {Box, Button, IconButton, Input, Modal, TextareaAutosize, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {createProject} from "../../../http/projectApi";
import HelpIcon from "@mui/icons-material/Help";


const ModalAddProject = (props) => {
    let navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = React.useState(false);
    const [projectName, setProjectName] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //console.log(props)


    //console.log(props.projectsState)
    //console.log(task)

    return (
        <div className={styles.ModalButtonWrapper}>


            <div className={styles.Wrapper}>
                <IconButton style={{color: 'rgba(64,111,106,0.73)', }}
                    onClick={() => handleOpen()}
                >
                    <HelpIcon fontSize='medium'/>
                </IconButton>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className={styles.ModalBoxWrapper}>
                            <div className={styles.ModalText}>Здесь вы можете отмечать дни в которые были продуктивны.
                            Данный функционал помогает выробатать дисциплинрованность и ответственность
                                внутри дня.</div>
                        <button className='button' onClick={() => handleClose()}>Закрыть</button>
                        </div>
                    </Box>
                </Modal>
            </div>

        </div>
    );
};

export default ModalAddProject;