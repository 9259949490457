import React from 'react';
import {connect} from "react-redux";
import {AddBoardCreator, AddProjectCreator, AddProjectsCreator} from "../../store/projects-reducer";
import Projects from "./Projects";
//console.log(state)

let mapStateToProps = (state) => {
    return {
        projectsState: state.projectsState,
        userState: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
            AddProjectDispatch: (project) => {
            dispatch(AddProjectCreator(project))
        }, AddProjectsDispatch: (projects) => {
            dispatch(AddProjectsCreator(projects))
        }
    }
}

const ProjectsContainer = connect(mapStateToProps, mapDispatchToProps)(Projects);

export default ProjectsContainer;