const ADD_STAT = 'ADD_STAT';



let initialState = {
    statistics: {
        tasks: []
    }
}

const statisticsReducer = (state = initialState, action) => {
    let id = Math.round(Math.random() * 10000000);

    switch (action.type) {

        case ADD_STAT:
            //console.log(action.data)
            return {
                ...state, statistics: { tasks: action.data }

            }

        default:
            return state;
    }
}

export const AddStatCreator = (data) => ({type: ADD_STAT,  data})

export default statisticsReducer;
