export const CheckPointStatus = (pointStatus) => {
    if (pointStatus === true) {
        return 'Выполненно'
    } else {
        return 'Ожидает'
    }
}

export const CheckColorTask = (color) => {
    if (color === 'red') {
        return {borderLeft: '10px solid #d02121'}
    } else if (color === 'orange') {
        return {borderLeft: '10px solid #e2b936'}
    }
}