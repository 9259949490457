import React from 'react';
import styles from './About.module.css'

const About = (props) => {

    return (
        <div className={styles.Wrapper}>

                <div className={styles.TitleH2}>О проекте</div>
            <p className={styles.text}>
                Этот проект был создан, что бы помочь всем добиться успеха в любых начинаниях.
                Мы верим, что через череду ежедневных мелких задач, в конечном итоге, можно прийти
                к своей заветной цели. Продуктивность человека, зависит от конечной награды, если награда слишком
                далеко, то это может серьёзно осложнить достиженение цели и приведёт к снижению продуктивности. 
                Но мы пытаемся сделать так, что бы вы каждый день видели какие задачи вы выполнинли на пути к достижению цели
                и это мотивироволо вас, как и наша система достижений, которая вам будет напоминать, что нельзя опускать руки!

            </p>
        </div>
    );
};

export default About;
