import React from 'react';
import {connect} from "react-redux";
import ProjectProfile from "./ProjectProfile";
import {
    AddOneTaskInProjectCreator,
    AddProjectsCreator,
    AddTaskInProjectCreator, EditOneTaskInProjectCreator,
    TaskChangePointStatusCreator, UpdateCreator
} from "../../../store/projects-reducer";
//console.log(state)

let mapStateToProps = (state) => {
    return {
        projectsState: state.projectsState,
        userState: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
           AddDispatchTaskInProject: (id, task) => {
            dispatch(AddTaskInProjectCreator(id, task))
        }, TaskChangePointStatusDispatch: (projectId, taskId, pointId, bool) => {
            dispatch(TaskChangePointStatusCreator(projectId, taskId, pointId, bool))
        }, AddProjectsDispatch: (projects) => {
            dispatch(AddProjectsCreator(projects))
        }, UpdateDispatch: (projects) => {
            dispatch(UpdateCreator(projects))
        }, EditOneTaskInProjectDispatch: (projectId, taskId, task) => {
            dispatch(EditOneTaskInProjectCreator(projectId, taskId, task))
        }, AddOneTaskInProjectDispatch: (projectId, task) => {
            dispatch(AddOneTaskInProjectCreator(projectId, task))
    },
    }
}

const ProjectProfileContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectProfile);

export default ProjectProfileContainer;