import React, {useEffect, useState} from 'react';
import {Link, Route, useNavigate} from "react-router-dom";
import styles from './NavBar.module.css'
import {Button} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from "js-cookie";
import LoginIcon from '@mui/icons-material/Login';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AddColor from "../Projects/ProjectProfile/ProjectProfileListTask/ProjectProfileListItem/ModalShowTask/AddColor";
import MobileMenu from "./MobileMenu";
import SideMenu from "./SideMenu";



const NavBar = (props) => {

    let navigate = useNavigate();

    const [width, setWidth] = useState(window.innerWidth);

    //console.log(props.userState)
    /*Cookies.set('name', 'value')
    Cookies.set('name', 'value2')
    let token = Cookies.get('name')
    console.log(token)*/

    const logout = () => {
        props.IsAuthDispatch(false)
        Cookies.remove('token')
        navigate('/')
    }

    const updateDimensions = (Width) => {
        return setWidth(Width)
    };

    window.addEventListener('resize', () => updateDimensions(window.innerWidth));

    //console.log(width)


    return (
        <div className={styles.Wrapper}>
            {(props.userState.isAuth === true) ?
                <div className={styles.navbar}>

                    <div className={styles.LogoWrapper} onClick={() => navigate('/')}>
                        {/*<img className={styles.LogoImage} src={Logo}/>*/}
                        <div className={styles.LogoText}>Biz Machine</div>
                        <div className={styles.navbarBeta}>Beta</div>
                    </div>
                    <div>


                        {(width < 1200) ?
                            <div className={styles.IconsWrapper}>
                                <SideMenu logout={logout}/>
                            </div>
                            :
                            <div className={styles.IconsWrapper}>
                                
                                <IconButton style={{color: '#1c6a62', marginRight: '16px'}}
                                            onClick={() => navigate('/tasks')}>
                                    <FormatListBulletedIcon fontSize='large'/>
                                </IconButton>
                                <IconButton style={{color: '#1c6a62', marginRight: '16px'}}
                                            onClick={() => navigate('/projects')}>
                                    <AllInboxIcon fontSize='large'/>
                                </IconButton>
                                {/*<IconButton style={{color: '#1c6a62', marginRight: '15px'}}
                                            onClick={() => navigate('/statistics')}>
                                    <QueryStatsIcon fontSize='large'/>
                                </IconButton>*/}
                                <IconButton style={{color: '#1c6a62', marginRight: '15px'}}
                                            onClick={() => navigate('/account')}>
                                    <AccountCircleIcon fontSize='large'/>
                                </IconButton>
                                <IconButton style={{color: '#1c6a62', marginRight: '15px'}} onClick={() => logout()}>
                                    <LogoutIcon fontSize='large'/>
                                </IconButton>
                            </div>
                        }


                    </div>
                </div>
                :
                <div className={styles.navbar}>
                    <div className={styles.LogoWrapper} onClick={() => navigate('/')}>
                        {/*<img className={styles.LogoImage} src={Logo}/>*/}
                        <div className={styles.LogoText}>Biz Machine</div>
                        <div className={styles.navbarBeta}>Beta</div>
                    </div>
                    <div className={styles.IconsWrapper}>
                        <IconButton onClick={() => navigate('/login')}>
                            <LoginIcon style={{color: '#1c6a62'}} fontSize='large'/>
                        </IconButton>
                    </div>
                </div>
            }
        </div>
    );
}
;

export default NavBar;