import React from 'react';
import styles from './AgreementBeta.module.css'



const AgreementBeta = () => {


    return (

        <div className={styles.Wrapper}>
            <div className={styles.TitleWrapper}>Условие участия в Бета-тестирование</div>
            <h3 className={styles.H3Wrapper}>Отказ от ответственности</h3>
            <div className={styles.DivWrapper}>Сервис работает в тестовом режиме, из-за чего могут происходить
            отключение и перезагрузки серверов без предупреждения. Так же из-за тестирование, может происходить сброс
            всех данных.</div>
            </div>

    );
};

export default AgreementBeta;