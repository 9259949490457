import React, {useEffect, useState} from 'react';
import {Link, NavLink, Route} from "react-router-dom";
import styles from './DayTasks.module.css'
import {connect} from "react-redux";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import ModalAddTask from "../ModalAddTask/ModalAddTask";
import {
    AddDayTaskCreator,
    AddTaskCreator,
    ChangeDayStatusCreator, ChangeDayTimeIsOverCreator,
    ChangeStatusCreator, DeleteDayTaskCreator,
    IsActiveCreator
} from "../../../store/tasks-reducer";
import DayTaskItem from "./DayTaskItem";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {deleteDayTask, fetchAllDayTasks} from "../../../http/taskApi";

let mapStateToProps = (state) => {
    return {
        state: state
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddDayTaskDispatch: (task) => {
            dispatch(AddDayTaskCreator(task))
        }, ChangeDayStatusDispatch: (id) => {
            dispatch(ChangeDayStatusCreator(id))
        }, ChangeDayTimeIsOverDispatch: (id) => {
            dispatch(ChangeDayTimeIsOverCreator(id))
        }, DeleteDayTaskDispatch: (id) => {
            dispatch(DeleteDayTaskCreator(id))
        }
    }
}

const DayTasks = (props) => {

    const Delete = async (taskId) => {
        await deleteDayTask(taskId, props.state.userState.user.id)//.then((data) => console.log('Delete taskId ' + taskId + ' User id ' + props.state.userState.user.id))
        await fetchAllDayTasks(props.state.userState.user.id).then((data) => props.AddDayTaskDispatch(data))
    }

    return (
        <div className={styles.DayTasksMainWrapper}>
            <div className={styles.DayTasksBlockWrapper}>
                <div className={styles.DayTaskTitleWrapper}>
                <ModalAddTask
                    AddTaskDispatch={props.AddDayTaskDispatch}
                    name='day'
                    state={props.state}/>
                </div>
                <div>{props.state.tasksState.dayTasks.map(el =>
                        <DayTaskItem key={el.id}
                                     status={el.status}
                                     ChangeDayStatusDispatch={props.ChangeDayStatusDispatch}
                                     ChangeDayTimeIsOverDispatch={props.ChangeDayTimeIsOverDispatch}
                                     DeleteDayTaskDispatch={props.DeleteDayTaskDispatch}
                                     AddDayTaskDispatch={props.AddDayTaskDispatch}
                                     Delete={Delete}
                                     id={el.id}
                                     task={el.task}
                                     startDate={el.startDate}
                                     timeIsOver={el.timeIsOver}
                                     userId={props.state.userState.user.id}
                        />
                    )}
                </div>

            </div>



        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DayTasks);