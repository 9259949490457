import React, {useEffect, useState} from 'react';
import styles from "./ModalShowTask.module.css"
import {
    Box,
    Button,
    IconButton,
    Input,
    LinearProgress,
    Modal,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {
    changePointTaskApi,
    changeStatusTaskApi, createTaskInProject, fetchAllProjects,
    getAllTasksInProject
} from "../../../../../../http/projectApi";
import {CheckPointStatus} from "../../../../../../Engines/ProjectsEngine";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddIcon from "@mui/icons-material/Add";
import AddColor from "./AddColor";
import AddPoint from "./AddPoint";
import ClearIcon from "@mui/icons-material/Clear";
import AddMoving from "../../../ProjectProfileListTask/ProjectProfileListItem/ModalShowTask/AddMoving";
import DeleteTask from '../../../ProjectProfileListTask/ProjectProfileListItem/ModalShowTask/DeleteTask';


const ModalShowTask = (props) => {
    let navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        width: '80%',
        height: '80%',
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        padding: '10px',
        overflow: 'auto',

    };

    const points = JSON.parse(props.task.points);
    const [progress, setProgress] = React.useState(0);
    //const [points, setPoints] = React.useState(JSON.parse(props.task.points));

    useEffect(() => {
        let lengthDone = 0;
        points.map(point => {
            if (point.status === true) {
                lengthDone += 1;
            }
        })
        //console.log(lengthDone * 100 / props.task.points.length)
        setProgress(lengthDone * 100 / points.length)
    }, [props.task]);


    const ChangePointStatus = async (e, pointId) => {

        const formData = new FormData()

        let array = points;
        array.map(point => {
            if (point.id === pointId) {
                //return {...point, test: true}
                point.status = true
            }
        })

        //formData.append('projectId', props.projectId)
        formData.append('taskId', props.task.id)
        formData.append('pointsArray', JSON.stringify(array))
        //await changePointTaskApi(formData).then(data => props.AddDispatchTaskInProject(props.projectId, data))
        await changePointTaskApi(formData).then(data => props.EditOneTaskInProjectDispatch(props.projectId, props.task.id, data))
    }

    const DeletePoint = async (e, pointId) => {

        const formData = new FormData()

        let array = points;
        let result = array.filter(item => item.id !== pointId)

        console.log(result)

        //formData.append('projectId', props.projectId)
        formData.append('taskId', props.task.id)
        formData.append('pointsArray', JSON.stringify(result))
        //await changePointTaskApi(formData).then(data => props.AddDispatchTaskInProject(props.projectId, data))
        await changePointTaskApi(formData).then(data => props.EditOneTaskInProjectDispatch(props.projectId, props.task.id, data))
    }


    const ChangeStatusTask = async (e, status) => {
        await changeStatusTaskApi(props.task.id, status).then(data => props.EditOneTaskInProjectDispatch(props.projectId, props.task.id, data))
    }

    let handleClose = () => {
        props.setOpen(false)
    }
    let handleOpen = () => {
        props.setOpen(false)
    }



    const CheckColor = (color) => {
        if (color === 'red') {
            return <div style={{
                backgroundColor: '#d02121',
                fontSize: '14px',
                borderRadius: '4px',
                padding: '7px 13px',
                letterSpacing: '1px',
                marginBottom: '20px',
                color: 'white'
            }}>Срочная задача</div>
        } else if (color === 'orange') {
            return <div style={{
                backgroundColor: '#e2b936',
                fontSize: '14px',
                borderRadius: '4px',
                padding: '7px 13px',
                letterSpacing: '1px',
                marginBottom: '20px',
                color: 'white'
            }}>Особая задача</div>
        }
    }

    //console.log(progress)

    return (
        <div >
            <div >
                <Modal
                    open={props.open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className={styles.Wrapper}>
                        {/*<div className={styles.Wrapper}>*/}
                        <div className={styles.ButtonsWrapper}>
                            <AddColor EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
                                      projectId={props.projectId}
                                      taskId={props.task.id}/>
                            <AddMoving EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
                                       projectId={props.projectId}
                                       taskId={props.task.id}/>
                                       <DeleteTask taskId={props.task.id} projectId={props.projectId}
                            AddDispatchTaskInProject={props.AddDispatchTaskInProject}
                            />
                        </div>
                        {/*<div className={styles.ContentBlockWrapper}>*/}
                        <div className={styles.ModalBoxWrapper}>
                            {CheckColor(props.task.color)}
                            <div className={styles.NameTitle}>
                                {/*<div className={styles.Title}>Название</div>*/}
                                {props.task.name}
                            </div>

                            {(progress === 100) ?
                                <div
                                    className={styles.Activate}
                                    onClick={(e) => ChangeStatusTask(e, 'done')}>
                                    Завершить?
                                </div> : null
                            }


                            <div className={styles.PointsBlockWrapper}>
                                <div className={styles.Title}>Подзадачи</div>
                                {points.map(point => {
                                        if (point.status === false) {
                                            return <div key={point.id} className={styles.Point}
                                                onClick={(e) => ChangePointStatus(e, point.id)}
                                            >
                                                <div className={styles.PointCheckWrapper}>
                                                    <CheckBoxOutlineBlankIcon/>
                                                </div>
                                                <div className={styles.PointTextWrapper}>
                                                    {point.point}
                                                </div>
                                                <div onClick={(e) => DeletePoint(e, point.id)} className={styles.PointDeleteWrapper}>
                                                    <IconButton >
                                                        <ClearIcon  style={{color: '#898989'}}/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        } else {
                                            return <div key={point.id} className={styles.Point}
                                                onClick={(e) => ChangePointStatus(e, point.id)}
                                            >
                                                <div className={styles.PointCheckWrapper}>
                                                    <CheckBoxIcon/>
                                                </div>
                                                <div className={styles.PointTextDoneWrapper}>
                                                    {point.point}
                                                </div>
                                                <div onClick={(e) => DeletePoint(e, point.id)} className={styles.PointDeleteWrapper}>
                                                    <IconButton>
                                                        <ClearIcon style={{color: '#898989'}}/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        }
                                    }
                                )}
                                <div style={{textAlign: "center", marginTop: '15px'}}>
                                    <AddPoint EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
                                              projectId={props.projectId}
                                              taskId={props.task.id} points={JSON.parse(props.task.points)} />
                                </div>
                            </div>
                            {(points.length > 0) ?
                                <div className={styles.LinearProgressWrapper}>
                                    <LinearProgress style={{padding: "2px", backgroundColor: 'rgba(78,173,170,0.53)',}}
                                                    sx={{'& .MuiLinearProgress-bar1Determinate': {
                                                            backgroundColor: '#358683',
                                                            color: '#358683'
                                                        }
                                                    }} variant="determinate"
                                                    value={(progress)}/>
                                    <div className={styles.LinearProgressNum}>{parseInt(progress)}%</div>
                                </div> : null

                            }
                            <div style={{textAlign: "center"}}>
                                <button style={{fontSize: '14px', marginTop: '25px', marginBottom: '25px'}} className='button' onClick={() => handleClose()}>
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>

        </div>
    );
};

export default ModalShowTask;