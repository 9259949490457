import React, {useEffect, useState} from 'react';
import styles from './DayTasks.module.css'
import {DateFormatEngines} from '../../../Engines/DateFormatEngines'
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import {changeStatusDayTask, deleteDayTask, fetchAllDayTasks, timeIsOverDayTask} from "../../../http/taskApi";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';


const DayTaskItem = (props) => {

    const [hours, setHours] = useState(24 - Math.trunc(((new Date().getTime() - Number(props.startDate)) / (1000 * 60 * 60)) % 24));


    const Success = async () => {
        if (props.status !== false) {
            await changeStatusDayTask(props.id, props.userId)//.then((data) => console.log('status changed ' + data))
            await fetchAllDayTasks(props.userId).then((data) => props.AddDayTaskDispatch(data))
        }
    }

    // Асинхронно проверяем вышло ли заданное время и отправляем запрос на сервер timeIsOver: false,
    // после изменения в базе timeIsOver на false, заного фетчим таски
    useEffect(() => {
        if (props.status !== false && props.timeIsOver !== true) {
            if (new Date().getTime() >= Number(props.startDate) + 86400193) {
                //console.log('Time Is Over ' + props.id)
                timeIsOverDayTask(props.id, props.userId).then((data) => data)
                fetchAllDayTasks(props.userId).then((data) => props.AddDayTaskDispatch(data))
            } else {
                //console.log('Time Is OK ' + props.id)
            }
        }
    }, [])

    const ThemeTask = () => {
        if (props.status === false) {
            return styles.DayTaskItemCompleted
        } else if (props.status === true) {
            return styles.DayTaskItem
        }
    }

    const ThemeTaskTime = () => {
        if (hours <= 24 && hours > 1) {
            return {color: '#9a9a9a', position: "absolute", left: '5px', fontWeight: 450, fontSize: '16px'}
        }  else if (hours <= 1) {
            return {color: 'rgba(231,12,12,0.87)', position: "absolute", left: '5px', fontWeight: 400, fontSize: '14px'}
        }
    }

    //console.log()

    return (
        <div>
            {(props.timeIsOver === false) ?
                <div className={styles.DayTaskElementWrapper}>

                    <div className={ThemeTask()} onClick={() => Success()}>

                        {(props.status === true) ?
                            <div style={ThemeTaskTime()}>
                                {hours}ч
                            </div> : null}
                        {/*<HourglassFullIcon style={{color: 'rgba(99,187,172,0.89)', position: "absolute", left: '5px'}}/>*/}
                        {props.task}
                    </div>
                    <IconButton onClick={() => props.Delete(props.id)}><ClearIcon/></IconButton>
                </div>
                :
                <div className={styles.DayOneTaskWrapper}>
                    <div className={styles.DayTaskItemTimeIsOver}>
                        <HourglassEmptyIcon style={{color: '#a12121', position: "absolute", left: '5px'}}/>
                        {props.task}
                    </div>
                    <IconButton onClick={() => props.Delete(props.id)}><ClearIcon/></IconButton>
                </div>
            }

        </div>
    );
};

export default DayTaskItem;