import React, {useState} from 'react';
import {Link, NavLink, Route} from "react-router-dom";
import styles from './TaskNav.module.css'
import {connect} from "react-redux";
import {IsActiveCreator} from "../../../store/tasks-reducer";

let mapStateToProps = (state) => {
    return {
        state: state
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        IsActiveDispatch: (data) => {
            dispatch(IsActiveCreator(data))
        }
    }
}

const TaskNav = (props) => {

    const [isActive, setIsActive] = useState();

    return (
        <div className={styles.MainWrapper}>
            <NavLink className={styles.LinkWrapper}
                     style={({ isActive }) => (isActive ? {backgroundColor: '#78b6af', color: '#ffffff'} : {backgroundColor: '#ffffff'})}
                     to='/tasks/day'>
                <b>День</b>
            </NavLink>
            <NavLink className={styles.LinkWrapper}  style={({ isActive }) =>
                (isActive ? {backgroundColor: '#78b6af', color: '#ffffff'} : {backgroundColor: '#ffffff'})} to='/tasks/week'>
                <b>Неделя</b>
            </NavLink>
            <NavLink className={styles.LinkWrapper}  style={({ isActive }) =>
                (isActive ? {backgroundColor: '#78b6af', color: '#ffffff'} : {backgroundColor: '#ffffff'})} to='/tasks/month'>
                <b>Месяц</b>
            </NavLink>
            <NavLink className={styles.LinkWrapper}  style={({ isActive }) =>
                (isActive ? {backgroundColor: '#78b6af', color: '#ffffff'} : {backgroundColor: '#ffffff'})} to='/tasks/year'>
                <b>Год</b>
            </NavLink>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskNav);