import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import styles from "./NavBar.module.css"
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from "js-cookie";
import LoginIcon from '@mui/icons-material/Login';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from "react-router-dom";

const SideMenu = (props) => {

    let navigate = useNavigate();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const SetNavigate = async (route) => {
        toggleDrawer('right', false)
        navigate(route)

    }

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                    <ListItem style={{color: '#406f6a'}} onClick={() => SetNavigate('projects')} key={'Проекты'} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AllInboxIcon style={{fontSize: '45px'}}/>
                            </ListItemIcon>
                            <ListItemText primary={'Проекты'} />
                        </ListItemButton>
                    </ListItem>
            </List>
            <List>
                <ListItem onClick={() => SetNavigate('tasks')} key={'Задачи'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <FormatListBulletedIcon style={{fontSize: '45px'}}/>
                        </ListItemIcon>
                        <ListItemText primary={'Задачи'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List>
                <ListItem onClick={() => SetNavigate('statistics')} key={'Статистика'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <QueryStatsIcon style={{fontSize: '45px'}}/>
                        </ListItemIcon>
                        <ListItemText primary={'Статистика'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List>
                <ListItem onClick={() => SetNavigate('account')} key={'Аккаунт'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <AccountCircleIcon style={{fontSize: '45px'}}/>
                        </ListItemIcon>
                        <ListItemText primary={'Аккаунт'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List>
                <ListItem onClick={() => props.logout()} key={'Выйти'} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <LogoutIcon style={{fontSize: '45px'}}/>
                        </ListItemIcon>
                        <ListItemText primary={'Выйти'} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
                <React.Fragment key={'right'}>
                    <MenuIcon style={{fontSize: '45px', color: 'rgba(35,120,111,0.81)', verticalAlign: 'middle'}} onClick={toggleDrawer('right', true)}/>
                    <Drawer
                        anchor={'right'}
                        open={state['right']}
                        onClose={toggleDrawer('right', false)}
                    >
                        {list('right')}
                    </Drawer>
                </React.Fragment>
        </div>
    );
}

export default SideMenu;