const ADD_GOAL = 'ADD_GOAL';
const ADD_DAY_TASK = 'ADD_DAY_TASK';
const ADD_WEEK_TASK = 'ADD_WEEK_TASK';
const ADD_MONTH_TASK = 'ADD_MONTH_TASK';
const ADD_YEAR_TASK = 'ADD_YEAR_TASK';
const DELETE_DAY_TASK = 'DELETE_DAY_TASK';
const DELETE_WEEK_TASK = 'DELETE_WEEK_TASK';
const DELETE_MONTH_TASK = 'DELETE_MONTH_TASK';
const DELETE_YEAR_TASK = 'DELETE_YEAR_TASK';
const SUCCESS_DAY_TASK = 'SUCCESS_DAY_TASK';
const SUCCESS_WEEK_TASK = 'SUCCESS_WEEK_TASK';
const SUCCESS_MONTH_TASK = 'SUCCESS_MONTH_TASK';
const SUCCESS_YEAR_TASK = 'SUCCESS_YEAR_TASK';
const TIME_IS_OVER_DAY_TASK = 'TIME_IS_OVER_DAY_TASK';
const TIME_IS_OVER_WEEK_TASK = 'TIME_IS_OVER_WEEK_TASK';
const TIME_IS_OVER_MONTH_TASK = 'TIME_IS_OVER_MONTH_TASK';
const TIME_IS_OVER_YEAR_TASK = 'TIME_IS_OVER_YEAR_TASK';
const IS_ACTIVE = 'IS_ACTIVE';


let initialState = {
    dayTasks: [],
    weekTasks: [],
    monthTasks: [],
    yearTasks: [],
    //isActiveNav: 'day',
}

const tasksReducer = (state = initialState, action) => {
    let id = Math.round(Math.random() * 10000000);

    switch (action.type) {

        case ADD_DAY_TASK:
            //console.log('click')
            return {
                ...state, dayTasks: action.task

            }
        case ADD_WEEK_TASK:
            //console.log('click')
            return {
                ...state, weekTasks: action.task

            }
        case ADD_MONTH_TASK:
            return {
                ...state, monthTasks: action.task

            }
        case ADD_YEAR_TASK:
            return {
                ...state, yearTasks: action.task

            }
        case DELETE_DAY_TASK:
            return {
                ...state,
                dayTasks: state.dayTasks.filter(task => task.id !== action.id)

            }
        case DELETE_WEEK_TASK:
            return {
                ...state,
                weekTasks: state.weekTasks.filter(task => task.id !== action.id)

            }
        case DELETE_MONTH_TASK:
            return {
                ...state,
                monthTasks: state.monthTasks.filter(task => task.id !== action.id)
            }
        case DELETE_YEAR_TASK:
            return {
                ...state,
                yearTasks: state.yearTasks.filter(task => task.id !== action.id)
            }
        case IS_ACTIVE:
            return {
                ...state, isActiveNav: action.data
            }

        case SUCCESS_DAY_TASK:
            return {
                ...state,
                dayTasks: state.dayTasks.map(el => {
                    if (el.id === action.id) {
                        return {...el,  status: false }
                    } else {
                        return el;
                    }

                })

            }
        case SUCCESS_WEEK_TASK:
            return {
                ...state,
                weekTasks: state.weekTasks.map(el => {
                    if (el.id === action.id) {
                        return {...el,  status: false }
                    } else {
                        return el;
                    }

                })

            }
        case SUCCESS_MONTH_TASK:
            return {
                ...state,
                monthTasks: state.monthTasks.map(el => {

                    if (el.id === action.id) {
                        return {...el,  status: false }
                    } else {
                        return el;
                    }

                })

            }
        case SUCCESS_YEAR_TASK:
            return {
                ...state,
                yearTasks: state.yearTasks.map(el => {

                    if (el.id === action.id) {
                        return {...el,  status: false }
                    } else {
                        return el;
                    }

                })

            }

        case TIME_IS_OVER_DAY_TASK:
            return {
                ...state,
                dayTasks: state.dayTasks.map(el => {

                    if (el.id === action.id) {
                        return {...el,  timeIsOver: true }
                    } else {
                        return el;
                    }

                })

            }
        case TIME_IS_OVER_WEEK_TASK:
            return {
                ...state,
                weekTasks: state.weekTasks.map(el => {

                    if (el.id === action.id) {
                        return {...el,  timeIsOver: true }
                    } else {
                        return el;
                    }

                })

            }
        case TIME_IS_OVER_MONTH_TASK:
            //console.log(action.flag)

            return {
                ...state,
                monthTasks: state.monthTasks.map(el => {

                    if (el.id === action.id) {
                        return {...el,  timeIsOver: true }
                    } else {
                        return el;
                    }

                })

            }
        case TIME_IS_OVER_YEAR_TASK:
            //console.log(action.flag)

            return {
                ...state,
                yearTasks: state.yearTasks.map(el => {
                    console.log(el)
                    if (el.id === action.id) {
                        return {...el,  timeIsOver: true }
                    } else {
                        return el;
                    }

                })

            }

        default:
            return state;
    }
}

export const IsActiveCreator = (data) => ({type: IS_ACTIVE,  data})
export const AddDayTaskCreator = (task) => ({type: ADD_DAY_TASK,  task})
export const AddWeekTaskCreator = (task) => ({type: ADD_WEEK_TASK,  task})
export const AddMonthTaskCreator = (task) => ({type: ADD_MONTH_TASK,  task})
export const AddYearTaskCreator = (task) => ({type: ADD_YEAR_TASK,  task})
export const DeleteDayTaskCreator = (id) => ({type: DELETE_DAY_TASK,  id})
export const DeleteWeekTaskCreator = (id) => ({type: DELETE_WEEK_TASK,  id})
export const DeleteMonthTaskCreator = (id) => ({type: DELETE_MONTH_TASK,  id})
export const DeleteYearTaskCreator = (id) => ({type: DELETE_YEAR_TASK,  id})
export const ChangeDayStatusCreator = (id) => ({type: SUCCESS_DAY_TASK,  id})
export const ChangeWeekStatusCreator = (id) => ({type: SUCCESS_WEEK_TASK,  id})
export const ChangeMonthStatusCreator = (id) => ({type: SUCCESS_MONTH_TASK,  id})
export const ChangeYearStatusCreator = (id) => ({type: SUCCESS_YEAR_TASK,  id})
export const ChangeDayTimeIsOverCreator = (id) => ({type: TIME_IS_OVER_DAY_TASK,  id})
export const ChangeWeekTimeIsOverCreator = (id) => ({type: TIME_IS_OVER_WEEK_TASK,  id})
export const ChangeMonthTimeIsOverCreator = (id) => ({type: TIME_IS_OVER_MONTH_TASK,  id})
export const ChangeYearTimeIsOverCreator = (id) => ({type: TIME_IS_OVER_YEAR_TASK,  id})

export default tasksReducer;
