import React, {useEffect, useState} from 'react';
import styles from './Statistics.module.css'
import {fetchAllUserStat} from "../../http/statApi";
import {fetchAllDayTasks, fetchAllMonthTasks, fetchAllWeekTasks, fetchAllYearTasks} from "../../http/taskApi";
import Charts from "./Chart/Charts";



const Statistics = (props) => {
    const [loading, setLoading] = useState(true);
    //const [dayTasksSuccess, setDayTasksSuccess] = useState(0);

    useEffect(()  => {
        fetchAllUserStat(props.userState.user.id).then((data) => props.AddStatDispatch(data))
            .finally( () => setLoading(false))
    },[])
    //console.log(props.statisticsState.statistics.tasks)

    const CheckAllTask = () => {
        let result = 0;
        props.statisticsState.statistics.tasks.map(el => {
            if (el.statName === 'add_day_task' || el.statName === 'add_week_task' || el.statName === 'add_month_task' || el.statName === 'add_year_task') {
                result += 1
            }
        })
        return result
    }

    const CheckAllSuccessTask = () => {
        let result = 0;
        props.statisticsState.statistics.tasks.map(el => {
            if (el.statName === 'success_day_task' || el.statName === 'success_week_task' || el.statName === 'success_month_task' || el.statName === 'success_year_task') {
                result += 1
            }
        })
        return result
    }

    const CheckAllTimeIsOverTask = () => {
        let result = 0;
        props.statisticsState.statistics.tasks.map(el => {
            if (el.statName === 'timeIsOver_day_task' || el.statName === 'timeIsOver_week_task' || el.statName === 'timeIsOver_month_task' || el.statName === 'timeIsOver_year_task') {
                result += 1
            }
        })
        return result
    }

    if (loading) {
        return <div className='Spinner'></div>
    }

    //console.log(props.statisticsState.statistics.tasks)
    return (
        <div className={styles.Wrapper}>
            <div className={styles.Title}>Статистика</div>
                {/*{(props.statisticsState.statistics.tasks[0] !== undefined) ?
                    props.statisticsState.statistics.tasks[0].map(el =>)
                    : null
                }*/}

            <div className={styles.WrapperStatTasks}>
                <div className={styles.StatTitle}>Созданно</div>

                    <div className={styles.StatNumber}>{CheckAllTask()}</div>
                </div>


            <div className={styles.WrapperStatTasks}>
                <div className={styles.StatTitle}>Выполненно</div>

                <div className={styles.StatNumber}>
                    {CheckAllSuccessTask()}</div>
            </div>


            <div className={styles.WrapperStatTasks}>
                <div className={styles.StatTitle}>Вышло время</div>

                <div className={styles.StatNumber}>
                    {CheckAllTimeIsOverTask()}</div>
            </div>

        <Charts userId={props.userState.user.id} allTasks={props.statisticsState.statistics.tasks}/>

        </div>
    );
};

export default Statistics;