import React, {useEffect, useState} from 'react';
import styles from './Blog.module.css'


const Blog = (props) => {

    


    return (
        <div className={styles.Wrapper}>
                <div className={styles.Title}>Blog</div>


        </div>
    );
};

export default Blog;