import React from 'react';
import {connect} from "react-redux";
import {AddUserCreator} from "../../store/user-reducer";
import {AddStatCreator} from "../../store/statistics-reducer";
import Statistics from "./Statistics";
import tasksReducer, {
    AddDayTaskCreator,
    AddMonthTaskCreator,
    AddWeekTaskCreator,
    AddYearTaskCreator
} from "../../store/tasks-reducer";


let mapStateToProps = (state) => {
    return {
        userState: state.userState,
        statisticsState: state.statisticsState,
        tasksState: state.tasksState,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddStatDispatch: (stat) => {
            dispatch(AddStatCreator(stat))
        }
    }
}

const StatisticsContainer = connect(mapStateToProps, mapDispatchToProps)(Statistics);

export default StatisticsContainer;