import React from 'react';
import {connect} from "react-redux";
import {AddUserCreator, IsAuthCreator} from "../../store/user-reducer";
import NavBar from "./NavBar";
//console.log(state)


let mapStateToProps = (state) => {
    return {
        userState: state.userState,
        tasksState: state.tasksState,

    }
}

let mapDispatchToProps = (dispatch) => {
    return {
         IsAuthDispatch: (bool) => {
            //console.log(props)
            dispatch(IsAuthCreator(bool))
        }, AddUserDispatch: (user) => {
            dispatch(AddUserCreator(user))
        }
    }
}

const NavBarContainer = connect(mapStateToProps, mapDispatchToProps)(NavBar);

export default NavBarContainer;