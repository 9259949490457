import React from 'react';
import {connect} from "react-redux";
import Account from "./Account";
import {AddSessionsCreator, AddUserCreator, IsAuthCreator} from "../../store/user-reducer";
import {AddStatCreator} from "../../store/statistics-reducer";


let mapStateToProps = (state) => {
    return {
        userState: state.userState,
        statisticsState: state.statisticsState,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddUserDispatch: (user) => {
            dispatch(AddUserCreator(user))
        }, AddStatDispatch: (data) => {
            dispatch(AddStatCreator(data))
        }, AddSessionsDispatch: (sessions) => {
            dispatch(AddSessionsCreator(sessions))
        }, AddUserDispatch: (user) => {
            dispatch(AddUserCreator(user))
        }
    }
}

const AccountContainer = connect(mapStateToProps, mapDispatchToProps)(Account);

export default AccountContainer;