import React, {useEffect} from 'react';

import {connect} from "react-redux";
import Tasks from "./Tasks";
import {
    AddDayTaskCreator,
    AddMonthTaskCreator,
    AddWeekTaskCreator,
    AddYearTaskCreator,
    IsActiveCreator
} from "../../store/tasks-reducer";



let mapStateToProps = (state) => {
    return {
        tasksState: state.tasksState,
        userState: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
         AddDayTaskDispatch: (task) => {
            dispatch(AddDayTaskCreator(task))
        },  AddWeekTaskDispatch: (task) => {
            dispatch(AddWeekTaskCreator(task))
        },  AddMonthTaskDispatch: (task) => {
            dispatch(AddMonthTaskCreator(task))
        },  AddYearTaskDispatch: (task) => {
            dispatch(AddYearTaskCreator(task))
        },
    }
}

const TasksContainer = connect(mapStateToProps, mapDispatchToProps)(Tasks);

export default TasksContainer;