import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from "./ModalShowTask.module.css"
import {setColorTask} from "../../../../../../http/projectApi";

const AddColor = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = async (event) => {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {

        setAnchorEl(null);
    };

    const SetColorTask = async (e, color) => {

        await setColorTask(props.taskId, color).then(data => props.EditOneTaskInProjectDispatch(props.projectId, props.taskId, data))
    }

    return (
        <div>
            <button
                id="basic-button"
                className={styles.MenuButton}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Срочность
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={(e) => SetColorTask(e,'none')}><div className={styles.squareWhite}></div>Обычная</MenuItem>
                <MenuItem onClick={(e) => SetColorTask(e,'red')}><div className={styles.squareRed}></div>Срочно</MenuItem>
                <MenuItem onClick={(e) => SetColorTask(e,'orange')}><div className={styles.squareOrange}></div>Особое</MenuItem>
            </Menu>
        </div>
    );
}

export default AddColor;