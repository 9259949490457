import React, {useState} from 'react';
import styles from './Password.module.css'
import ErrorIcon from "@mui/icons-material/Error";
import {check, login, changePasswordRequest} from "../../../http/userApi";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Password = (props) => {

    const [userPassword, setUserPassword] = useState('');
    const [userNewPassword, setUserNewPassword] = useState('');
    const [userNewPasswordAgain, setUserNewPasswordAgain] = useState('');
    const [requestDelay, setRequestDelay] = useState(false);

    const [tagResponse, setTagResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    let errors = []
    let greens = []

    const changePassword = async () => {
        setLoading(true)
        errors = []
        greens = []
        let data;
        try {
            
            // Проверка, что пароль есть
            if (userPassword === undefined) {
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Введите пароль</div>)
            }

            // Проверка, что поле - строка
            if (typeof userPassword !== typeof '' && typeof userNewPassword !== typeof '' && typeof userNewPasswordAgain !== typeof '') {           
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Поле не является строкой</div>)
            }
            
            // Проверка, что пароли совпадают
            if (userNewPassword !== userNewPasswordAgain) {
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Пароли не сопадают</div>)
            }

            // Проверка, что пароль и почта соответствуют требованиям

           //if (userPassword !== undefined) {
            //if (userPassword.length > 64 || userPassword.length < 5 ) {
             //   errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Пароль не соответствуют требованиям</div>)
            //}
           //}
            // Проверка на то, что на клиенте нет ошибок и можно делать запрос на сервер
            if (errors.length === 0) {
                
                data = await changePasswordRequest(props.state.email.trim(), userPassword.trim(), userNewPassword.trim()).finally(() => setLoading(false))
                .then((data) => console.log(data))
                .then(() => greens.push(<div ><label style={{fontWeight: 900, }}>Server Success:</label> &nbsp;&nbsp;Пароль успешно изменён</div>))
                // Проверка на то, что с сервера пришли данные
                /*if (data !== null && data !== undefined) {
                    props.AddUserDispatch(data)
                    props.IsAuthDispatch(true)
                    setLoading(false)
                } else {
                    errors.push(<div ><label style={{fontWeight: 900, }}>Server Error:</label> &nbsp;&nbsp;Пришли неверные данные с сервера</div>)
                }*/
            } 
        } catch (e) {
            errors.push(<div ><label style={{fontWeight: 900, }}>Server Error:</label> &nbsp;&nbsp;{e.response.status} {e.response.data.message}</div>)
        }


        setLoading(false)
        if (errors.length > 0) {
            return  setTagResponse(<div className={styles.ErrorInfoWrapper}>
                {errors.map((value, i) => <div className={styles.ErrorInfoElement} key={i}><ErrorIcon style={{fontSize: '25px',  marginRight: '10px', }}/>{value}</div>)}
                </div>)
        } else if (greens.length > 0) {
            return setTagResponse(<div className={styles.SuccessInfoWrapper}>
            {greens.map((value, i) => <div className={styles.SuccessInfoElement} key={i}><CheckCircleIcon style={{fontSize: '25px',  marginRight: '10px', }}/>{value}</div>)}
            </div>)
        }
    }



        /*if (requestDelay === true) {
            return alert('Слишком много запросов')
        } else {
            setRequestDelay(true)
            setTimeout(
                () => setRequestDelay(false),
                3000,

            );
        }*/


    return (

            <div className={styles.Wrapper}>
                <div className={styles.PasswordTitle}>Сменить пароль</div>
                {tagResponse}
                <div className={styles.InputsWrapper}>
                    <div className={styles.PasswordInputWrapper}>
                        <label style={{color: '#428e8c', marginBottom: '5px'}}>Старый пароль</label>
                        <input
                            maxLength={64}
                            className={styles.InputAuth}
                            onChange={(e) => setUserPassword(e.target.value)}
                            value={userPassword} type="password" name="Password"/>
                    </div>
                    <div className={styles.PasswordInputWrapper}>
                        <label style={{color: '#428e8c', marginBottom: '5px'}}>Новый пароль</label>
                        <input
                            maxLength={64}
                            className={styles.InputAuth}
                            onChange={(e) => setUserNewPassword(e.target.value)}
                            value={userNewPassword} type="password" name="NewPassword"/>
                    </div>
                    <div className={styles.PasswordInputWrapper}>
                        <label style={{color: '#428e8c', marginBottom: '5px'}}>Повторите новый пароль</label>
                        <input
                            maxLength={64}
                            className={styles.InputAuth}
                            onChange={(e) => setUserNewPasswordAgain(e.target.value)}
                            value={userNewPasswordAgain} type="password" name="NewPassword"/>
                    </div>
                </div>
                <div style={{margin: '10px', textAlign: "center"}}>
                    <button
                        className={styles.PasswordButton}
                        type="submit" style={{margin: '30px'}} onClick={() => changePassword()}>Сменить пароль
                    </button>
                </div>
            </div>

    );
};

export default Password;