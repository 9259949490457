import React from 'react';
import {connect} from "react-redux";
import Login from "./Login";
import {AddUserCreator, IsAuthCreator} from "../../../store/user-reducer";
//console.log(state)

let mapStateToProps = (state) => {
    return {
        user: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddUserDispatch: (user) => {
            dispatch(AddUserCreator(user))
        }, IsAuthDispatch: (bool) => {
            //console.log(props)
            dispatch(IsAuthCreator(bool))
        }
    }
}

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;