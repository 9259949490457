import {applyMiddleware, combineReducers, createStore} from "redux";
import tasksReducer from "./tasks-reducer";
import userReducer from "./user-reducer";
import statisticsReducer from "./statistics-reducer";
import projectsReducer from "./projects-reducer";
import productivityReducer from "./productivity-reducer";



let reducers = combineReducers({
    tasksState: tasksReducer,
    userState: userReducer,
    statisticsState: statisticsReducer,
    projectsState: projectsReducer,
    productivityState: productivityReducer,
})

let store = createStore(reducers);

export default store;