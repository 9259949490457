const ADD_TASK_IN_PROJECT = 'ADD_TASK_IN_PROJECT';
const EDIT_ONE_TASK_IN_PROJECT = 'EDIT_ONE_TASK_IN_PROJECT';
const TASK_CHANGE_POINT_STATUS = 'TASK_CHANGE_POINT_STATUS';
const ADD_ONE_TASK_IN_PROJECT = 'ADD_ONE_TASK_IN_PROJECT';
const ADD_PROJECT = 'ADD_PROJECT';
const ADD_PROJECTS = 'ADD_PROJECTS';
const UPDATE = 'UPDATE';


let initialState = {
    projects: [],
    update: true,

}

const projectsReducer = (state = initialState, action) => {


    switch (action.type) {

        case UPDATE:
            //console.log('update')
            return {
                ...state, update: !state.update
            }

        case ADD_PROJECT:
            return {
                ...state, projects: [...state.projects,
                    action.project]

            }
        case ADD_PROJECTS:

            //console.log(action)
            return {
                ...state, projects: action.projects

            }
        case ADD_TASK_IN_PROJECT:
            //console.log(action.id, action.task)

            return {
                ...state, projects: state.projects.map(project => {
                    if (project.id === action.id) {
                        //console.log(project)
                        return {...project, tasks: action.task}
                    } else {
                        return {...project}
                    }
                })

            }

        case EDIT_ONE_TASK_IN_PROJECT:
            //console.log(action.projectId, action.taskId, action.task)
            //console.log( state.projects.find(project => action.projectId === project.id));
            return {
                //...state,  projects: state.projects.find(project => action.projectId === project.id)
                ...state, projects: state.projects.map(project => {
                    if (project.id === action.projectId) {
                        return { ...project, tasks:
                            project.tasks.map(task => {
                                if (task.id === action.taskId) {
                                    //console.log('success')
                                    return action.task
                                } else {
                                    return {...task}
                                }
                            })
                        }
                    } else {
                        return {...project}
                    }
                })
            }

        case ADD_ONE_TASK_IN_PROJECT:
            //console.log(action.projectId, action.taskId, action.task)
            //console.log( state.projects.find(project => action.projectId === project.id));
            return {
                //...state,  projects: state.projects.find(project => action.projectId === project.id)
                ...state, projects: state.projects.map(project => {
                    if (project.id === action.projectId) {
                        return {...project, tasks: [...project.tasks, action.task] }
                    } else {
                        return {...project}
                    }
                })
            }
        default:
            return state;
    }
}

export const UpdateCreator = () => ({type: UPDATE})
export const AddProjectCreator = (project) => ({type: ADD_PROJECT,  project})
export const AddProjectsCreator = (projects) => ({type: ADD_PROJECTS,  projects})
export const AddTaskInProjectCreator = (id, task) => ({type: ADD_TASK_IN_PROJECT,  id, task})
export const EditOneTaskInProjectCreator = (projectId, taskId, task) => ({type: EDIT_ONE_TASK_IN_PROJECT,  projectId, taskId, task})
export const AddOneTaskInProjectCreator = (projectId, task) => ({type: ADD_ONE_TASK_IN_PROJECT,  projectId, task})
export const TaskChangePointStatusCreator = (projectId, taskId, pointId, bool) => ({type: TASK_CHANGE_POINT_STATUS, projectId, taskId, pointId, bool})


export default projectsReducer;
