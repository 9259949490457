import React from 'react';
import styles from './Charts.module.css'
import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";




const Charts = (props) => {

    //console.log(dayTasksSuccess)
    return (
        <div className={styles.Wrapper}>
            <div className={styles.Title}>Активность за последний месяц</div>
            <ChartOne userId={props.userId} allTasks={props.allTasks}/>
            {/*<ChartTwo allTasks={props.allTasks}/>*/}
        </div>
    );
};

export default Charts;