import React from 'react';
import {Route, Routes,} from 'react-router-dom';

import {connect} from "react-redux";
import StartContainer from "./StartPage/Start/StartContainer";
import Agreement from "./Agreement/Agreement";
import About from "./About/About";
import Contacts from "./Contacts/Contacts";
import UnknownPage from "./UnknownPage/UnknownPage";
import AccessRecovery from "./Auth/AccessRecovery";
import LoginContainer from "./Auth/Login/LoginContainer";
import RegistrationContainer from "./Auth/Registration/RegistrationContainer";
import AgreementBeta from "./AgreementBeta/AgreementBeta";
import BlogContainer from './Blog/BlogContainer';


let mapStateToProps = (state) => {
    return {
        user: state.userState
    }
}

const StartRouter = (props) => {

    return (
        <Routes>
        <Route path='/' element={<StartContainer/>} />
        <Route path='/about' element={<About/>} />
        <Route path='*' element={<UnknownPage/>} />
        <Route path='/contacts' element={<Contacts/>} />
        {/* <Route path='/blog' element={<BlogContainer/>} /> */}
        <Route path='/login' element={<LoginContainer/>} />
        <Route path='/registration' element={<RegistrationContainer/>} />
        <Route path='/recovery' element={<AccessRecovery/>} />
        {/*<Route path='/account' element={<AccountContainer/>} />
               <Route path='/statistics' element={<StatisticsContainer/>} />*/}
        <Route path='/privacy-policy' element={<Agreement/>} />
        <Route path='/beta' element={<AgreementBeta/>} />
    </Routes>
    )
}

export default connect(mapStateToProps)(StartRouter);