import React, {useEffect} from 'react';
import ListItem from "./ProjectProfileListItem/ListItem";
import {fetchAllProjects, getAllTasksInProject} from "../../../../http/projectApi";


const ListInProgress = (props) => {

    useEffect(() => {
        if (props.project.id !== undefined) {
            getAllTasksInProject(props.project.id).then((tasks) => props.AddDispatchTaskInProject(props.project.id, tasks))
        }
        //console.log(props.project)
    }, [props.project.id])


    return (
        <div>
            {(props.project.tasks !== undefined) ?
                props.project.tasks.map(task => {
                        if (task.status === 'in_progress') {
                            return <ListItem key={task.id}
                                EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
                                AddDispatchTaskInProject={props.AddDispatchTaskInProject}
                                TaskChangePointStatusDispatch={props.TaskChangePointStatusDispatch}
                                task={task}
                                projectId={props.project.id}
                                project={props.project}
                            />
                        }
                    }
                )
                : null
            }
        </div>
    );
};

export default ListInProgress;