import React from 'react';

const UnknownPage = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: '35px',
            color: '#3c8078'
        }}>
            Ничего не нашлось =(
        </div>
    );
};

export default UnknownPage;