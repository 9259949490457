import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from "./ModalShowTask.module.css"
import {changeStatusTaskApi, setColorTask} from "../../../../../../http/projectApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const AddColor = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = async (event) => {

        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {

        setAnchorEl(null);
    };

    const ChangeStatusTask = async (status) => {

        //await changeStatusTaskApi(props.projectId, status).then(data => props.EditOneTaskInProjectDispatch(props.projectId, props.taskId, data))
        await changeStatusTaskApi(props.taskId, status).then(data => props.EditOneTaskInProjectDispatch(props.projectId, props.taskId, data))

    }

    return (
        <div>
            <button
                id="basic-button"
                className={styles.MenuButton}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Перемещение
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => ChangeStatusTask('await')}><KeyboardArrowRightIcon style={{color: 'grey'}}/>Очередь задач</MenuItem>
                <MenuItem onClick={() => ChangeStatusTask('in_progress')}><KeyboardArrowRightIcon style={{color: 'grey'}}/>В процессе</MenuItem>
                <MenuItem onClick={() => ChangeStatusTask('done')}><KeyboardArrowRightIcon style={{color: 'grey'}}/>Выполненные</MenuItem>
            </Menu>
        </div>
    );
}

export default AddColor;