import React, {useState} from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import {check, login, registration} from "../../../http/userApi";
import {connect} from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from '../Auth.module.css'
import {Button, CircularProgress, Icon, IconButton} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import InputIcon from '@mui/icons-material/Input';
import {CheckEmailBeforeSendingOnServer, CheckPasswordBeforeSendingOnServer} from "../../../Engines/service";




const Login = (props) => {
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [tagResponse, setTagResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const [emailInputStyles, setEmailInputStyles] = useState(null);
    const [passwordInputStyles, setPasswordInputStyles] = useState(null);

    let errors = []

    const click = async () => {
        setTagResponse(null)
        setLoading(true)
        setEmailInputStyles(null)
        setPasswordInputStyles(null)
        errors = []
        let data;
        try {
            // Проверка, что пароль и почта соответствуют требованиям
            if (CheckEmailBeforeSendingOnServer(email) === false) {
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Почта не соответствует требованиям</div>) 
                
                setEmailInputStyles({borderColor: "#ce6363"})
            }
            if (CheckPasswordBeforeSendingOnServer(password) === false) {
            
                setPasswordInputStyles({borderColor: "#ce6363"})
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Пароль не соответствует требованиям</div>)
            }

            // Проверка, что в поле строка
            if (typeof email !== typeof '' && typeof password !== typeof '') {
            
                setPasswordInputStyles({borderColor: "#ce6363"})
                setEmailInputStyles({borderColor: "#ce6363"})
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Поле не является строкой</div>)
            }

            //console.log(typeof email === typeof '', typeof password === typeof '')

            // Проверка на то, что на клиенте нет ошибок и можно делать запрос на сервер
            if (errors.length === 0) {
                //console.log("request on server")
                data = await login(email.trim(), password.trim()).finally( () => setLoading(false))

                // Проверка на то, что с сервера пришли данные
                if (data !== null && data !== undefined) {
                    props.AddUserDispatch(data)
                    props.IsAuthDispatch(true)
                    setLoading(false)
                    return navigate("/tasks")
                    
                } else {
                    errors.push(<div ><label style={{fontWeight: 900, }}>Server Error:</label> &nbsp;&nbsp;Пришли неверные данные с сервера</div>)
                }
            }
            //setLoading(false)
        } catch (e) {
            errors.push(<div ><label style={{fontWeight: 900, }}>Server Error:</label> 
                &nbsp;&nbsp;
                {(e.response !== undefined) ? "Status " + e.response.status : null} &nbsp;
                {(e.response !== undefined) ? e.response.data.message : null} 
                {(e.message === 'timeout of 5000ms exceeded') ? "Время ожидания ответа от сервера истекло" : null}</div>)       
                 }


        setLoading(false)
        return  setTagResponse(
            <div className={styles.ErrorInfoWrapper}>
                {errors.map((value, i) => <div className={styles.ErrorInfoElement} key={i}><ErrorIcon style={{fontSize: '25px',  marginRight: '10px', }}/>{value}</div>)}
            </div>)
    }



    

    return (
        <div className={styles.Wrapper}>
            <div className={styles.AuthBlockWrapper}>
                <h2 style={{color: '#2c6866'}}>Авторизация</h2>
                {tagResponse}
                <input className={styles.InputAuth}
                        style={emailInputStyles}
                       placeholder='Введите почту'
                       type='text'
                       value={email}
                       maxLength={64}
                       onChange={(e) => setEmail(e.target.value)}
                />
                <input className={styles.InputAuth}
                        style={passwordInputStyles}
                       placeholder='Введите пароль'
                       type='password'
                       maxLength={64}
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                />
                <div style={{color: '#2c6866', marginTop: '5px', marginBottom: '15px'}}>
                    <NavLink className={styles.regText} to='/recovery'>Восстановление доступа</NavLink>
                </div>


                <div style={{color: '#2c6866', margin: '15px'}}>
                    Нет аккаунта? <NavLink className={styles.regText} to='/registration'>Зарегистрируйтесь!</NavLink>
                </div>
                
                <button
                    className={styles.ButtonAuth}
                    onClick={click}
                >
                    {/* Войти*/}
                    
                    
                    {(loading === false) ?  'Авторизоваться':
                        <CircularProgress style={{color: '#ffffff',}} size="2.5rem" fontSize='small'/>}
                </button>

            </div>
        </div>
    );
};

export default Login;