import React, {useEffect, useState} from 'react';
import {Link, NavLink, Route} from "react-router-dom";
import styles from './WeekTasks.module.css'
import {connect} from "react-redux";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import ModalAddTask from "../ModalAddTask/ModalAddTask";

import WeekTaskItem from "./WeekTaskItem";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {deleteWeekTask, fetchAllWeekTasks} from "../../../http/taskApi";
import {
    AddWeekTaskCreator,
    ChangeWeekStatusCreator,
    ChangeWeekTimeIsOverCreator,
    DeleteWeekTaskCreator
} from "../../../store/tasks-reducer";
import {AddStatCreator} from "../../../store/statistics-reducer";
import {fetchAllUserStat} from "../../../http/statApi";

let mapStateToProps = (state) => {
    return {
        state: state
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddWeekTaskDispatch: (task) => {
            dispatch(AddWeekTaskCreator(task))
        }, ChangeWeekStatusDispatch: (id) => {
            dispatch(ChangeWeekStatusCreator(id))
        }, ChangeWeekTimeIsOverDispatch: (id) => {
            dispatch(ChangeWeekTimeIsOverCreator(id))
        }, DeleteWeekTaskDispatch: (id) => {
            dispatch(DeleteWeekTaskCreator(id))
        }, AddStatDispatch: (data) => {
            dispatch(AddStatCreator(data))
        }
    }
}

const WeekTasks = (props) => {

    const Delete = async (taskId) => {
        await deleteWeekTask(taskId, props.state.userState.user.id)//.then((data) => console.log('Delete taskId ' + taskId + ' User id ' + props.state.userState.user.id))
        await fetchAllWeekTasks(props.state.userState.user.id).then((data) => props.AddWeekTaskDispatch(data))
    }

    return (
        <div className={styles.WeekTasksMainWrapper}>
            <div className={styles.WeekTasksBlockWrapper}>
                <div className={styles.WeekTaskTitleWrapper}>
                <ModalAddTask
                    AddTaskDispatch={props.AddWeekTaskDispatch}
                    name='week'
                    state={props.state}/>
                </div>
                <div>{props.state.tasksState.weekTasks.map(el => {
                       return <WeekTaskItem key={el.id}
                                      status={el.status}
                                      ChangeWeekStatusDispatch={props.ChangeWeekStatusDispatch}
                                      ChangeWeekTimeIsOverDispatch={props.ChangeWeekTimeIsOverDispatch}
                                      DeleteWeekTaskDispatch={props.DeleteWeekTaskDispatch}
                                      AddWeekTaskDispatch={props.AddWeekTaskDispatch}
                                      AddStatDispatch={props.AddStatDispatch}
                                      Delete={Delete}
                                      id={el.id}
                                      task={el.task}
                                      startDate={el.startDate}
                                      timeIsOver={el.timeIsOver}
                                      userId={props.state.userState.user.id}
                        />
                })}
                </div>

            </div>



        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WeekTasks);