import React, {useEffect, useState} from 'react';
import {Link, NavLink, Route} from "react-router-dom";
import styles from './MonthTasks.module.css'
import {connect} from "react-redux";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import ModalAddTask from "../ModalAddTask/ModalAddTask";


import AddCircleIcon from '@mui/icons-material/AddCircle';
import {deleteMonthTask, fetchAllMonthTasks} from "../../../http/taskApi";
import {
    AddMonthTaskCreator,
    ChangeMonthStatusCreator,
    ChangeMonthTimeIsOverCreator, DeleteMonthTaskCreator
} from "../../../store/tasks-reducer";
import MonthTaskItem from "./MonthTaskItem";

let mapStateToProps = (state) => {
    return {
        state: state
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddMonthTaskDispatch: (task) => {
            dispatch(AddMonthTaskCreator(task))
        }, ChangeMonthStatusDispatch: (id) => {
            dispatch(ChangeMonthStatusCreator(id))
        }, ChangeMonthTimeIsOverDispatch: (id) => {
            dispatch(ChangeMonthTimeIsOverCreator(id))
        }, DeleteMonthTaskDispatch: (id) => {
            dispatch(DeleteMonthTaskCreator(id))
        }
    }
}

const MonthTasks = (props) => {


    const Delete = async (taskId) => {
        await deleteMonthTask(taskId, props.state.userState.user.id)//.then((data) => console.log('Delete taskId ' + taskId + ' User id ' + props.state.userState.user.id))
        await fetchAllMonthTasks(props.state.userState.user.id).then((data) => props.AddMonthTaskDispatch(data))
    }

    return (
        <div className={styles.MonthTasksMainWrapper}>
            <div className={styles.MonthTasksBlockWrapper}>
                <div className={styles.MonthTaskTitleWrapper}>
                    <ModalAddTask
                        AddTaskDispatch={props.AddMonthTaskDispatch}
                        name='month'
                        state={props.state}/>
                </div>
                <div>{props.state.tasksState.monthTasks.map(el =>
                    <MonthTaskItem key={el.id}
                                 status={el.status}
                                 ChangeMonthStatusDispatch={props.ChangeMonthStatusDispatch}
                                 ChangeMonthTimeIsOverDispatch={props.ChangeMonthTimeIsOverDispatch}
                                 DeleteMonthTaskDispatch={props.DeleteMonthTaskDispatch}
                                 AddMonthTaskDispatch={props.AddMonthTaskDispatch}
                                 Delete={Delete}
                                 id={el.id}
                                 task={el.task}
                                 startDate={el.startDate}
                                 timeIsOver={el.timeIsOver}
                                 userId={props.state.userState.user.id}
                    />
                )}
                </div>

            </div>



        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthTasks);