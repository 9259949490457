import React, {useEffect, useState} from 'react';
import styles from './Sessions.module.css'
import {Navigate} from "react-router-dom";
import {fetchSessions} from "../../../http/userApi";


const Sessions = (props) => {

    useEffect(() => {
        fetchSessions(props.userState.user.id).then((sessions) => props.AddSessionsDispatch(sessions))
    },[])


    let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC'
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleString("ru", options)
    }

    const IpFilter = (ip) => {
        if (ip !== null) {
            if (ip.substring(0, 7) === '::ffff:') {
                return  ip.substring(7)
             } else {
                 return ip
             }
        } else {
            return null
        }
    }
    //console.log(props.userState.userSessions)
    return (
        <div className={styles.Wrapper}>

                    <div className={styles.SessionTitle}>История авторизаций</div>
                    {(props.userState.userSessions !== undefined) ?
                        props.userState.userSessions.map(session =>
                        <div className={styles.sessionWrapper} key={session.id}>
                            <div className={styles.SessionElement}>{`${new Date(Number(session.date)).toString().slice(0, 21)} — ${IpFilter(session.ip)}`}</div>
                        </div>)
                            :
                        null
                    }

        </div>
    );
};

export default Sessions;