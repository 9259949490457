import React, {useState} from 'react';
import styles from "./Auth.module.css";
import {
    CheckEmailBeforeSendingOnServer,
    CheckPasswordBeforeSendingOnServer,
    GeneratePassword
} from "../../Engines/service";
import {accessRecovery, login} from "../../http/userApi";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {CircularProgress} from "@mui/material";
import {Link, NavLink, useLocation} from "react-router-dom";





const AccessRecovery = () => {

    const [email, setEmail] = useState('');
    const [tagResponse, setTagResponse] = useState(null);
    const [loading, setLoading] = useState(false);


    let errors = []
    let greens = []

    const click = async () => {
        setTagResponse(null)
        setLoading(true)
        errors = []
        greens = []
        let data;
        
            // Проверка, что пароль и почта соответствуют требованиям

            if (CheckEmailBeforeSendingOnServer(email) === false) {
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Почта не соответствуют требованиям</div>)
            }

            // Проверка, что в поле строка
            if (typeof email !== typeof '') {
            
                errors.push(<div ><label style={{fontWeight: 900, }}>Client Error:</label> &nbsp;&nbsp;Поле не является строкой</div>)
            }

            // Проверка на то, что на клиенте нет ошибок и можно делать запрос на сервер
            if (errors.length === 0) {
                
                // Запрос на сервер
               data = await accessRecovery(email)//.finally(() => setLoading(false))
                // Удачно, добавляем успешное уведомление
                .then(() => greens.push(<div ><label style={{fontWeight: 900, }}>Server Success:</label> &nbsp;&nbsp;Вам выслан временный пароль</div>))

                // С сервера пришла ошибка
                .catch((e) => {
                               
                errors.push(<div ><label style={{fontWeight: 900, }}>Server Error:</label> 
                    &nbsp;&nbsp;
                {(e.response !== undefined) ? "Status " + e.response.status : null} &nbsp;
                {(e.response !== undefined) ? e.response.data.message : null} 
                {(e.message === 'timeout of 5000ms exceeded') ? "Время ожидания ответа от сервера истекло" : null}</div>) 

                })
                
            }
        //console.log(errors)
        //console.log(greens)
        //console.log(test.response.data.message)
        setLoading(false)
        if (errors.length > 0) {
            return  setTagResponse(<div className={styles.ErrorInfoWrapper}>
                {errors.map((value, i) => <div className={styles.ErrorInfoElement} key={i}><ErrorIcon style={{fontSize: '25px',  marginRight: '10px', }}/>{value}</div>)}
                </div>)
        } else if (greens.length > 0) {
            return setTagResponse(<div className={styles.SuccessInfoWrapper}>
            {greens.map((value, i) => <div className={styles.SuccessInfoElement} key={i}><CheckCircleIcon style={{fontSize: '25px',  marginRight: '10px', }}/>{value}</div>)}
            </div>)
        }
        }

    
    return (
        <div className={styles.Wrapper}>
            <div className={styles.AuthBlockWrapper}>
                <h2 style={{color: '#2c6866'}}>Восстановление доступа</h2>
                {tagResponse}
                <input className={styles.InputAuth}
                       placeholder='Введите email'
                       value={email}
                       maxLength={64}
                       onChange={(e) => setEmail(e.target.value)}
                />
                <button
                    className={styles.ButtonAuth}
                    onClick={click}
                >

                    {(loading === false) ? 'Восстановить' :
                        <CircularProgress style={{color: '#ffffff',}} size="2.5rem" fontSize='small'/>}
                </button>

                <div style={{color: '#2c6866'}}>
                    <NavLink className={styles.regText} to='/login'>Войти</NavLink>
                </div>

            </div>
        </div>
    );
};

export default AccessRecovery;