export function GeneratePassword (length, charset) {
    let password = '';
    for(let i = 0; i < length; ++i){
        password += charset[
            Math.floor(
                Math.random() * charset.length
            )
            ];
    }
    return password;
}

export function CheckPasswordBeforeSendingOnServer (password) {
        if (password.length <= 64) {
            if (password.length >= 5) {
                return true
            }
        }
    return false
}

export function CheckEmailBeforeSendingOnServer (Email) {
    if (Email.length <= 64) {
        if (Email.length >= 6) {
            return true
        }
    }
    return false
}