const SET_DATA = 'SET_DATA';
const MOMENT_DATE = 'MOMENT_DATE';



let initialState = {
    data: [],
    momentDate: null,

}

const productivityReducer = (state = initialState, action) => {


    switch (action.type) {

        case SET_DATA:
            //console.log(action.data)
            return {
                ...state, data: action.data
            }

        case MOMENT_DATE:
            console.log('test' + action.data)
            return {
                ...state, data: [...state.data.map(date => (date.id === action.data) ? {...date, value: date.value + 1} : date)]
                //...state, ...state.data.map(date => console.log(date.id ))
            }


        default:
            return state;
    }
}

export const SetDataCreator = (data) => ({type: SET_DATA, data})
export const MomentDateCreator = (data) => ({type: MOMENT_DATE, data})


export default productivityReducer;
