import {$authHost, $host} from "./index";


export const createProject = async (formData) => {
    const {data} = await $authHost.post('api/project/createProject', formData)
    return data;
}

export const createTaskInProject = async (formData) => {
    const {data} = await $authHost.post('api/project/createTaskInProject', formData)
    return data;
}

export const fetchAllProjects = async (id) => {
    const {data} = await $authHost.get('api/project/getAllProjects', {params: {  id  }})
    return data;
}

export const fetchOneProject = async (userId, projectId) => {
    const {data} = await $authHost.get('api/project/getOneProject', {params: {  userId, projectId  }})
    return data;
}

export const getAllTasksInProject = async (projectId) => {
    //console.log(projectId)
    const {data} = await $authHost.get('api/project/getAllTasksInProject', {params: {  projectId  }})
    return data;
}

export const changePointTaskApi = async (formData) => {
    //console.log(projectId)
    const {data} = await $authHost.post('api/project/changePointTask', formData)
    return data;
}

export const changeStatusTaskApi = async (taskId, status) => {
    //console.log(projectId)
    const {data} = await $authHost.get('api/project/changeStatusTask', {params: {  taskId, status  }})
    return data;
}

export const setColorTask = async (taskId, color) => {
    //console.log(projectId)
    const {data} = await $authHost.get('api/project/setColorTask', {params: {  taskId, color  }})
    return data;
}

export const deleteProjectTaskApi = async (taskId) => {
    //console.log(projectId)
    const {data} = await $authHost.get('api/project/deleteProjectTask', {params: {  taskId  }})
    return data;
}

export const deleteProjectApi = async (projectId) => {
    //console.log(projectId)
    const {data} = await $authHost.get('api/project/deleteProject', {params: {  projectId  }})
    return data;
}
