import React from 'react';
import styles from './Contacts.module.css'
import {TextareaAutosize} from "@mui/material";
import {Link} from "react-router-dom";

const Contacts = (props) => {

    return (
        <div className={styles.Wrapper}>

                <div className={styles.TitleH2}>Контакты для связи</div>
            <div style={{display: "flex", flexDirection: "row"}}>
            <div className={styles.text}>По всем вопросам &nbsp;&nbsp;&mdash;&nbsp;&nbsp;</div>
                <div style={{color: '#1d6360', fontSize: "18px", fontWeight: 500}}>hello@bizmachine.ru</div>
            </div>
        </div>
    );
};

export default Contacts;