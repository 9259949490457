import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie'
import {fetchAllDayTasks} from "./taskApi";

export const registration = async (email, password) => {
    
    const {data} = await $host.post('api/user/registration', {email, password, role: 'USER'})
    Cookies.set('token', data.token, { expires: 7 }, { secure: true })
    //console.log(jwt_decode(data.token))
    return jwt_decode(data.token)
}

export const login = async (email, password) => {
    const {data} = await $host.post('api/user/login', {email, password})
    Cookies.set('token', data.token, { expires: 7 }, { secure: true })
    //console.log(jwt_decode(data.token))
    return jwt_decode(data.token)
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth' )
    Cookies.set('token', data.token, { expires: 7 }, { secure: true })
    //console.log(jwt_decode(data.token))
    return jwt_decode(data.token)
}


export const fetchActualUserData = async (email) => {
    const {data} = await $authHost.post('api/user/actualUserData', {email})
    //console.log(jwt_decode(data.token))
    Cookies.set('token', data.token, { expires: 7 }, { secure: true })
    return jwt_decode(data.token)
}



export const accessRecovery = async (email) => {
    const {data} = await $host.post('api/user/recovery', {email})
    //Cookies.set('token', data.token, { expires: 7 }, { secure: true })
    //return jwt_decode(data.token)
    //console.log(data)
    return data;
}

export const changePasswordRequest = async (email, password, newPassword) => {
    const {data} = await $authHost.post('api/user/changePassword', {email, password, newPassword})
    //console.log(data)
    Cookies.set('token', data.token, { expires: 7 }, { secure: true })
    return jwt_decode(data.token)
}

export const fetchSessions = async (id) => {
    const {data} = await $authHost('api/user/getSessions', {params: {  id  }})
    return data;
}

