import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";


export const createDayTask = async (form) => {
    const {data} = await $authHost.post('api/task/createDayTask', form)

    return data;
}
export const createWeekTask = async (form) => {
    const {data} = await $authHost.post('api/task/createWeekTask', form)
    return data;
}
export const createMonthTask = async (form) => {
    const {data} = await $authHost.post('api/task/createMonthTask', form)
    return data;
}
export const createYearTask = async (form) => {
    const {data} = await $authHost.post('api/task/createYearTask', form)
    return data;
}


export const fetchAllDayTasks = async (id) => {
    const {data} = await $authHost('api/task/getAllDayTasks', {params: {  id  }})
    return data;
}
export const fetchAllWeekTasks = async (id) => {
    const {data} = await $authHost('api/task/getAllWeekTasks', {params: {  id  }})
    return data;
}
export const fetchAllMonthTasks = async (id) => {
    const {data} = await $authHost('api/task/getAllMonthTasks', {params: {  id  }})
    return data;
}
export const fetchAllYearTasks = async (id) => {
    const {data} = await $authHost('api/task/getAllYearTasks', {params: {  id  }})
    return data;
}



export const deleteDayTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/deleteDayTask', {params: { taskId, userId  }})
    return data;
}
export const deleteWeekTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/deleteWeekTask', {params: { taskId, userId  }})
    return data;
}
export const deleteMonthTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/deleteMonthTask', {params: { taskId, userId  }})
    return data;
}
export const deleteYearTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/deleteYearTask', {params: { taskId, userId  }})
    return data;
}



export const changeStatusDayTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/changeStatusDayTask', {params: { taskId, userId  }})
    return data;
}
export const changeStatusWeekTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/changeStatusWeekTask', {params: { taskId, userId  }})
    return data;
}
export const changeStatusMonthTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/changeStatusMonthTask', {params: { taskId, userId  }})
    return data;
}
export const changeStatusYearTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/changeStatusYearTask', {params: { taskId, userId  }})
    return data;
}



export const timeIsOverDayTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/timeIsOverDayTask', {params: { taskId, userId  }})
    return data;
}
export const timeIsOverWeekTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/timeIsOverWeekTask', {params: { taskId, userId  }})
    return data;
}
export const timeIsOverMonthTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/timeIsOverMonthTask', {params: { taskId, userId  }})
    return data;
}
export const timeIsOverYearTask = async (taskId, userId) => {
    const {data} = await $authHost('api/task/timeIsOverYearTask', {params: { taskId, userId  }})
    return data;
}







/*
export const fetchOnePost = async (id) => {
    const {data} = await $host.get('api/blog/' + id)
    return data;
}

export const fetchManyBusinessIdeas = async (ArrayId) => {
    //console.log(ArrayId)
    const {data} = await $authHost.post('api/businessIdea/getBusinessIdeas', ArrayId)
    return data
}

export const fetchAllRating = async (form) => {
    //console.log(ArrayId)
    const {data} = await $host.post('api/businessIdea/getRating', form)
    return data
}*/
