import React from 'react';
import styles from "./ModalAddProjects.module.css"
import {Box, Button, IconButton, Input, Modal, TextareaAutosize, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {createProject} from "../../../http/projectApi";


const ModalAddProject = (props) => {
    let navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = React.useState(false);
    const [projectName, setProjectName] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //console.log(props)

    const SaveProject  = async ()  => {
        const formData = new FormData()
        formData.append('name', projectName)
        //console.log(projectName)
        formData.append('userId', props.userState.user.id)
        //props.AddProjectDispatch({id: id, name: project, tasks: []})
        if (projectName !== '') {
            await createProject(formData).then(data => props.AddProjectDispatch(data))
            setProjectName('')
            handleClose()
        } else {
            return alert('Название проекта не должны быть пустым')
        }

        //navigate('/projects' + '/' + id)
    }

    //console.log(props.projectsState)
    //console.log(task)

    return (
        <div className={styles.ModalButtonWrapper}>


            <div className={styles.Wrapper}>
                <button className='button' style={{marginLeft: '20px', fontWeight: '600' }}  onClick={handleOpen}>
                    Создать
                </button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className={styles.ModalBoxWrapper}>
                        <input
                            className='input'
                            maxLength={100}
                            placeholder="Название проекта"
                            style={{ width: 350, marginBottom: '25px' }}
                            value={projectName} onChange={e => setProjectName(e.target.value)}/>
                        <button className='button' onClick={() => SaveProject()}>Сохранить</button>
                        </div>
                    </Box>
                </Modal>
            </div>

        </div>
    );
};

export default ModalAddProject;