import React, {PureComponent, useEffect, useState} from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {fetchAllUserStat} from "../../../http/statApi";
import styles from './Charts.module.css'
import {connect} from "react-redux";
import {
    AddWeekTaskCreator,
    ChangeWeekStatusCreator,
    ChangeWeekTimeIsOverCreator,
    DeleteWeekTaskCreator
} from "../../../store/tasks-reducer";
import {AddStatCreator} from "../../../store/statistics-reducer";


let mapStateToProps = (state) => {
    return {
        statisticsState: state.statisticsState,
        userState: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        AddWeekTaskDispatch: (task) => {
            dispatch(AddWeekTaskCreator(task))
        }, ChangeWeekStatusDispatch: (id) => {
            dispatch(ChangeWeekStatusCreator(id))
        }, ChangeWeekTimeIsOverDispatch: (id) => {
            dispatch(ChangeWeekTimeIsOverCreator(id))
        }, DeleteWeekTaskDispatch: (id) => {
            dispatch(DeleteWeekTaskCreator(id))
        }, AddStatDispatch: (data) => {
            dispatch(AddStatCreator(data))
        }
    }
}


const ChartOne = (props) => {
    //const [loading, setLoading] = useState(true);
    const [arrayCheck, setArrayCheck] = useState([])

    let result = 0;

    let now = new Date();

    let time = now.getTime();
    now = new Date(time - (time % 86400000));

    let arr = [];
    let arr2 = [];
    let arr3 = [];

    for (let i = 0; i < 30; i++, now.setDate(now.getDate() - 1)) {
        //console.log(now.getTime())
        arr.push({name: new Date(now.getTime())});
        arr3.push({name: new Date(now.getTime()).toString().slice(0, 15), activity: 0});
        arr2.push(now.getDate() + '.' + (now.getMonth() + 1));
        //arr2.push({name: now.getDate() + '.' + (now.getMonth() + 1) + "." + now.getFullYear()});
    }

    //console.log(props.userId)

    useEffect(()  => {
        fetchAllUserStat(props.userId).then((data) => props.AddStatDispatch(data))
    }, [])
    useEffect(()  => {



                //.finally( () => setLoading(false))


        if (props.statisticsState.statistics.tasks !== undefined) {
            let test = props.statisticsState.statistics.tasks.map(el => {


                arr3.forEach((ChartEl)=>((new Date(Number(el.date)).toString().slice(0, 15) === ChartEl.name.toString().slice(0, 15)) && el.statName !== 'timeIsOver_day_task' && el.statName !== 'timeIsOver_week_task' && el.statName !== 'timeIsOver_month_task' && el.statName !== 'timeIsOver_year_task')
                    ?
                    ChartEl.activity = ChartEl.activity + 1
                    //setArrayCheck([...arrayCheck,  {name: ChartEl.name, activity: ChartEl.activity + 1}])
                    :
                    ChartEl.name= ChartEl.name )
                    //setArrayCheck([...arrayCheck, {name: ChartEl.name}]))

            })
            setArrayCheck(arr3.reverse())
            //arr3 = []
            //arr3.reverse()

        }
        //console.log(arrayCheck)
    },[props.statisticsState.statistics.tasks])

    /*if (loading) {
        return <div className='Spinner'></div>
    }*/

    //console.log(arrayCheck)
    return (
        <div className={styles.ChartOneWrapper}>
            <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={400}
                data={arrayCheck}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="activity" stroke="#406f6a"  strokeWidth={2} fill="#77c4bc" />
            </AreaChart>
        </ResponsiveContainer>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOne);
