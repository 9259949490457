import Cookies from "js-cookie";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {check} from "./http/userApi";
import {AddUserCreator, IsAuthCreator} from "./store/user-reducer";
import jwt_decode from "jwt-decode";
import MainPage from "./Components/MainPage/MainPage";
import StartPage from "./Components/StartPage/StartPage";
//
let mapStateToProps = (state) => {
    return {
        user: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        IsAuthDispatch: (bool) => {
            dispatch(IsAuthCreator(bool))
        }, AddUserDispatch: (user) => {
            dispatch(AddUserCreator(user))
        }
    }
}

function App(props) {

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        try {
            check().then( (data) => {
                props.IsAuthDispatch(true)
                    props.AddUserDispatch(data)
            }).finally( () => setLoading(false))
        } catch (e) {
            console.log('Check Auth Error', e);
        }

    },[props.user.isAuth])


    if (loading) {
        return <div className='Spinner'></div>
    }

    //console.log(props.user);

    const ChoosePage = () => {
        if (props.user.isAuth === true) {
            return <MainPage/>
        } else {
            return <StartPage/>
        }
    }

  return ChoosePage()
}

export default connect(mapStateToProps, mapDispatchToProps)(App);