import {connect} from "react-redux";
import NavBarContainer from "../NavBar/NavBarContainer";
import {AddUserCreator, IsAuthCreator} from "../../store/user-reducer";
import Footer from "../Footer/Footer";
import StartRouter from "../StartRouter";
import styles from './StartPage.module.css';


let mapStateToProps = (state) => {
    return {
        user: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        IsAuthDispatch: (bool) => {
            dispatch(IsAuthCreator(bool))
        }, AddUserDispatch: (user) => {
            dispatch(AddUserCreator(user))
        }
    }
}

function StartPage(props) {

    return (
        <div className={styles.Wrapper}>
            <div className={styles.Navbar}><NavBarContainer/></div>
            <div className={styles.Column}>
                <div className={styles.Content}><StartRouter/></div>
            </div>
            <div  className={styles.Footer}><Footer/></div>
        </div>
    );
}
/**/
export default connect(mapStateToProps, mapDispatchToProps)(StartPage);