import React, {useEffect} from 'react';

import {connect} from "react-redux";

import {IsActiveCreator} from "../../../store/tasks-reducer";
import Start from "./Start";



let mapStateToProps = (state) => {
    return {
        userState: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        /*IsActiveDispatch: (data) => {
            dispatch(IsActiveCreator(data))
        }*/
    }
}

const TasksContainer = connect(mapStateToProps, mapDispatchToProps)(Start);

export default TasksContainer;