import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from "@mui/icons-material/Add";
import {IconButton, TextareaAutosize} from "@mui/material";
import {changePointTaskApi, setColorTask} from "../../../../../../http/projectApi";
import styles from "./ModalShowTask.module.css"
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

const AddPoint = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [point, setPoint] = React.useState('');
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const RequestAddPoint = async (e) => {

        let id = Math.round(Math.random() * 10000000);
        let test2 = props.points
        let object = {id: id, point: point, status: false}

        let result = Object.assign([object, ...test2])

        const formData = new FormData()

        if (JSON.stringify(result).length > 2999) {
            return alert('Превышен лимит')
        }

        formData.append('taskId', props.taskId)
        formData.append('pointsArray', JSON.stringify(result))
        await changePointTaskApi(formData).then(data => props.EditOneTaskInProjectDispatch(props.projectId, props.taskId, data))
        setPoint('')
        handleClose()

    }

    return (
        <div>

            <IconButton aria-describedby={id}  onClick={(e) => handleClick(e)}>
                <AddIcon style={{fontSize: '35px', color: '#5eb3b0',}}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                    <TextareaAutosize
                        className={styles.Input}
                        minRows={1}
                        maxRows={10}
                        maxLength={1000}
                        placeholder="Подзадача"
                        style={{width: 350,}}
                        value={point}
                        onChange={e => setPoint(e.target.value)}
                    />
                    <div>
                        <IconButton onClick={(e) => RequestAddPoint(e)}>
                            <FileDownloadDoneIcon style={{fontSize: '35px', color: '#5eb3b0',}}/>
                        </IconButton>
                    </div>
                </div>
            </Popover>
        </div>
    );
}

export default AddPoint;