import React from 'react';
import styles from "./ModalAddTask.module.css"
import {Box, Button, IconButton, Input, Modal, TextareaAutosize, TextField, Typography} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useHotkeys } from 'react-hotkeys-hook'

import {
    createDayTask,
    createMonthTask,
    createWeekTask, createYearTask,
    fetchAllDayTasks,
    fetchAllMonthTasks, fetchAllWeekTasks, fetchAllYearTasks
} from "../../../http/taskApi";

const ModalAddTask = (props) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const [task, setTask] = React.useState('');
    
    useHotkeys('ctrl+x', () => setOpen(true))
    

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //console.log(props.name)

    const  SaveTask  = async ()  => {


        if (task === '') {
            return alert("Поле не должно быть пустым")
        }

        const formData = new FormData()
        formData.append('task', task)
        formData.append('userId', props.state.userState.user.id)
        if (props.name === 'day') {
            await createDayTask(formData).then(data => handleClose())
            
            .catch(error => alert(error.response.data.message))
            await fetchAllDayTasks(props.state.userState.user.id).then((data) => props.AddTaskDispatch(data))
        } else if (props.name === 'week') {
            await createWeekTask(formData).then(data => handleClose())

            .catch(error => alert(error.response.data.message))
            await fetchAllWeekTasks(props.state.userState.user.id).then((data) => props.AddTaskDispatch(data))
        } else if (props.name === 'month') {
            await createMonthTask(formData).then(data => handleClose())

            .catch(error => alert(error.response.data.message))
            await fetchAllMonthTasks(props.state.userState.user.id).then((data) => props.AddTaskDispatch(data))
        } else if (props.name === 'year') {
            await createYearTask(formData).then(data => handleClose())

            .catch(error => alert(error.response.data.message))
            await fetchAllYearTasks(props.state.userState.user.id).then((data) => props.AddTaskDispatch(data))
        }

        setTask('')
        handleClose()
    }

    const ref = useHotkeys<HTMLParagraphElement>('enter', () => SaveTask())

    return (
        <div>
            <div >
                <div className={styles.OpenModalButtonWrapper}> 
                <div className={styles.OpenModalButton} onClick={handleOpen}> 
                      
                    <div className={styles.OpenModalButtonText}>Добавить задачу</div>
                    
                </div>
                <div className={styles.arrowLeft}></div>
                <div className={styles.HotKeyLeftText}>Ctrl+X</div>
                </div>
                
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className={styles.ModalBoxWrapper}>
                        <TextareaAutosize
                        autoFocus
                            className={styles.ModalBoxTextarea}
                            minRows={3}
                            placeholder="Task"
                            maxLength={64}
                            style={{ width: '80%' }}
                            value={task} onChange={e => setTask(e.target.value)}
                            onKeyDown={(e) => {if (e.key === 'Enter') {
                                SaveTask()
                              }}}/>

                              <div className={styles.SaveTaskModalButtonWrapper}> 


                              <div className={styles.SaveTaskModalButton} onClick={SaveTask}> 
                      
                              <div className={styles.SaveTaskModalButtonText} >Сохранить</div>
                      
                                </div>
                                

                                <div className={styles.arrowUp}></div>
                                <div className={styles.HotKeyUpText}>Enter</div>
                        </div>
                        </div>

                    </Box>
                </Modal>
            </div>

        </div>
    );
};

export default ModalAddTask;