import React, {useEffect, useState} from 'react';
import styles from './YearTasks.module.css'
import {DateFormatEngines} from '../../../Engines/DateFormatEngines'
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {
    changeStatusWeekTask,
    changeStatusYearTask,
    deleteYearTask, fetchAllWeekTasks,
    fetchAllYearTasks,
    timeIsOverYearTask
} from "../../../http/taskApi";

const YearTaskItem = (props) => {

    const [days, setDays] = useState(365 - Math.trunc(((new Date().getTime() - Number(props.startDate)) / (1000 * 60 * 60 * 24) % 30)));
    //const [year, setDays] = useState(1);

    const Success = async () => {
        if (props.status !== false) {
            await changeStatusYearTask(props.id, props.userId)//.then((data) => console.log('status changed ' + data))
            await fetchAllYearTasks(props.userId).then((data) => props.AddYearTaskDispatch(data))
        }
    }

    // Асинхронно проверяем вышло ли заданное время и отправляем запрос на сервер timeIsOver: false,
    // после изменения в базе timeIsOver на false, заного фетчим таски
    useEffect(() => {
        if (props.status !== false && props.timeIsOver !== true) {
            if (new Date().getTime() >= Number(props.startDate) + 31536070445) {
                console.log('Time Is Over ' + props.id)
                timeIsOverYearTask(props.id, props.userId).then((data) => data)
                fetchAllYearTasks(props.userId).then((data) => props.AddYearTaskDispatch(data))
            } else {
                console.log('Time Is OK ' + props.id)
            }
        }
    }, [])

    const ThemeTask = () => {
        if (props.status === false) {
            return styles.YearTaskItemCompleted
        } else if (props.status === true) {
            return styles.YearTaskItem
        }
    }

    const ThemeTaskTime = () => {
        if (days <= 365 && days > 1) {
            return {color: '#9a9a9a', position: "absolute", left: '5px', fontWeight: 450, fontSize: '16px'}
        }  else if (days <= 1) {
            return {color: 'rgba(231,12,12,0.87)', position: "absolute", left: '5px', fontWeight: 400, fontSize: '14px'}
        }
    }

    //console.log(days)
   // console.log(((new Date().getTime() - Number(props.startDate)) / (1000 * 60 * 60 * 24) % 30))

    return (
        <div>
            {(props.timeIsOver === false) ?
                <div className={styles.YearOneTaskWrapper}>
                    <div className={ThemeTask()} onClick={() => Success()}>
                        {(props.status === true) ?
                            <div style={ThemeTaskTime()}>
                                {days}д
                            </div> : null}
                        {props.task}
                    </div>
                    <IconButton onClick={() => props.Delete(props.id)}><ClearIcon/></IconButton>
                </div>
                :
                <div className={styles.YearOneTaskWrapper}>
                    <div className={styles.YearTaskItemTimeIsOver}>
                        <HourglassEmptyIcon style={{color: '#a12121', position: "absolute", left: '5px'}}/>
                        {props.task}
                    </div>
                    <IconButton onClick={() => props.Delete(props.id)}><ClearIcon/></IconButton>
                </div>
            }

        </div>
    );
};

export default YearTaskItem;