import React, {useEffect} from 'react';
import styles from "./ListItem.module.css";
import ModalShowTask from "./ModalShowTask/ModalShowTask";
import {changeStatusPointTask} from "../../../../../http/projectApi";
import {Box, Button, LinearProgress, Modal} from "@mui/material";
import {CheckColorTask, CheckPointStatus} from "../../../../../Engines/ProjectsEngine";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const ListItem = (props) => {

    const [open, setOpen] = React.useState(false);
    const [donePoints, setDonePoints] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [points, setPoints] = React.useState(JSON.parse(props.task.points));

    let Open = (e) => {
        setOpen(!open)
    }

    //let DonePoints = 0;
    //let DonePoints = 0;
    useEffect(() => {
        let DonePoints = 0;
        JSON.parse(props.task.points).map(point => {
            if (point.status === true) {
                DonePoints += 1;
                //setCount(DonePoints)
                //setDonePoints(prev => prev + 1)
            }
            return  setDonePoints(DonePoints)
        })
    }, [props.task])

    //console.log(donePoints)
    //console.log(props.task)

    return (
        <div>
            <ModalShowTask
                AddDispatchTaskInProject={props.AddDispatchTaskInProject}
                TaskChangePointStatusDispatch={props.TaskChangePointStatusDispatch}
                task={props.task}
                projectId={props.projectId}
                open={open}
                setOpen={setOpen}
                UpdateDispatch={props.UpdateDispatch}
                EditOneTaskInProjectDispatch={props.EditOneTaskInProjectDispatch}
            />
            <div style={CheckColorTask(props.task.color)} onClick={(event) => Open(event)}
                 className={styles.TaskWrapper}>

                <div className={styles.Title}>{props.task.name}</div>

                {(JSON.parse(props.task.points).length > 0) ?
                    <div className={styles.PointsWrapper}>
                        <CheckBoxOutlinedIcon style={{ color: 'rgba(64,111,106,0.78)', marginRight: '5px', fontSize: '22px'}}/>
                        {donePoints}/{JSON.parse(props.task.points).length}
                    </div> : null
                }

            </div>
        </div>

    );
};

export default ListItem;