const IS_AUTH = 'IS_AUTH';
const ADD_USER = 'ADD_USER';
const ADD_SESSIONS = 'ADD_SESSIONS';



let initialState = {
    isAuth: false,
    user: {},
    userSessions: [],
}

const userReducer = (state = initialState, action) => {
    //let id = Math.round(Math.random() * 10000000);

    switch (action.type) {

        case IS_AUTH:

            return {
                ...state, isAuth: action.bool

            }

        case ADD_USER:
            //console.log(action.user.email)
            return {

                ...state,
                user: action.user


            }

        case ADD_SESSIONS:
            //console.log(action.sessions)
            return {

                ...state,
                userSessions: action.sessions


            }
        default:
            return state;
    }
}

export const IsAuthCreator = (bool) => ({type: IS_AUTH, bool})
export const AddUserCreator = (user) => ({type: ADD_USER, user})
export const AddSessionsCreator = (sessions) => ({type: ADD_SESSIONS, sessions})

export default userReducer;