import React, {useEffect, useState} from 'react';
import styles from "./ModalAddTaskInProject.module.css";
import {Box, Button, IconButton, Input, Modal, TextareaAutosize, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {createProject, createTaskInProject, getAllTasksInProject} from "../../../../../http/projectApi";

const ModalAddTaskInProject = (props) => {
    let navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 4,
    };
    const [countStages, setCountStages] = useState(0);
    const [ArrayStages, setArrayStages] = useState([]);
    const [inputListStages, setInputListStages] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [taskName, setTaskName] = React.useState('');
    const [taskPoints, setTaskPoints] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const AddTaskInProject = async () => {

        const formData = new FormData()
        formData.append('name', taskName)
        formData.append('projectId', props.project.id)
        formData.append('pointsArray', JSON.stringify(ArrayStages))
        if (taskName !== '') {

            await createTaskInProject(formData).then(data => props.AddOneTaskInProjectDispatch(props.project.id, data))
            //await getAllTasksInProject(props.project.id).then((tasks) => props.AddDispatchTaskInProject(props.project.id, tasks))
            handleClose()
            setTaskName('')
            setInputListStages([])
            setArrayStages([])
            setCountStages(0)
        } else {
            return alert('Название задачи не должны быть пустым')
        }

    }

    const onAddBtnClickStages = () => {
        setInputListStages(inputListStages.concat(<InputStages key={inputListStages.length}/>));
    };

    const InputStages = () => {


        useEffect(() => {
            setCountStages(prev => prev + 1)
            setArrayStages([...ArrayStages, {id: countStages, point: 'Empty', status: false}])

        }, []);

        //console.log(ArrayStages)
        return <div>
            <TextareaAutosize
                className={styles.Input}
                minRows={1}
                maxRows={10}
                maxLength={1000}
                placeholder="Подзадача"
                style={{width: 350,}}
                onChange={e => setArrayStages(ArrayStages => ArrayStages.map(ingredient => (ingredient.id === countStages) ? ({
                    ...ingredient,
                    point: e.target.value
                }) : ingredient))}
            />
        </div>

    }

    return (
        <div className={styles.ModalButtonWrapper}>
            <div>
                <IconButton onClick={handleOpen}
                            style={{position: "absolute", right: '5px', top: '5px'}}><AddIcon/></IconButton>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className={styles.ModalBoxWrapper}>
                            {/*<div className={styles.Title}>Новая задача</div>*/}
                            <div className={styles.TitleBlockWrapper}>
                                <input
                                    className='input'
                                    maxLength={100}
                                    placeholder="Название задачи"
                                    value={taskName} onChange={e => setTaskName(e.target.value)}/>
                            </div>
                            <div className={styles.TasksBlockWrapper}>
                                <div className={styles.SubTitle}>Подзадачи</div>
                                {inputListStages}
                                <IconButton style={{margin: '5px'}} onClick={onAddBtnClickStages}>
                                    <AddIcon style={{fontSize: '35px', color: '#5eb3b0',}}/>
                                </IconButton>
                            </div>
                            <div style={{textAlign: "center"}}>
                                <button className='button' onClick={() => AddTaskInProject()}>Сохранить</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>

        </div>
    );
};

export default ModalAddTaskInProject;