import * as React from 'react';
import styles from "./ModalShowTask.module.css"
import { deleteProjectTaskApi, getAllTasksInProject } from '../../../../../../http/projectApi';


const DeleteTask = (props) => {
    

    const DeleteProjectTask = async (taskId, projectId) => {

        // Асинхронно удаляем Таск из проекта и запрашиваем обновлённый список тасков для проекта
        await deleteProjectTaskApi(taskId)
        if (projectId !== undefined) {
            getAllTasksInProject(projectId).then((tasks) => props.AddDispatchTaskInProject(projectId, tasks))
        }
        
    }


    return (
        <button onClick={() => DeleteProjectTask(props.taskId, props.projectId)} className={styles.MenuButton}>
           Удалить
        </button>
    );
}

export default DeleteTask;