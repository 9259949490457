import React, {useEffect, useState} from 'react';
import styles from './Email.module.css'
import { fetchActualUserData } from '../../../http/userApi';





const Email = (props) => {
    

    useEffect(() => {
        fetchActualUserData(props.state.email).then( (data) => {
                props.AddUserDispatch(data)
        })
        .catch((e) => console.log(e))
    },[])

    //console.log(props.state)
    return (
        <div className={styles.Wrapper}>
                <div className={styles.EmailTitle}>Почта</div>
            <div className={styles.EmailWrapper}>
                {(props.state.isActivated === true) ?
                    /*<CheckCircleOutlineIcon style={{marginRight: '10px', verticalAlign: 'middle', color: 'rgba(39,132,56,0.83)'}}/>*/
                    <div className={styles.emailLabelTrue}>Подтверждено</div>
                    :
                    <div className={styles.emailLabelFalse}>Не подтверждено</div>
                }
                <div className={styles.Email}>{props.state.email}</div>
            </div>


        </div>
    );
};

export default Email;