import Cookies from "js-cookie";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import jwt_decode from "jwt-decode";

import SubNav from "../SubNav/SubNav";
import MainRouter from "../MainRouter";
import NavBarContainer from "../NavBar/NavBarContainer";
import {AddUserCreator, IsAuthCreator} from "../../store/user-reducer";
import {check} from "../../http/userApi";
import styles from './MainPage.module.css';
import SideMenu from "../NavBar/SideMenu";
import IconButton from "@mui/material/IconButton";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import Footer from "../Footer/Footer";
import {Button, Snackbar} from "@mui/material";

/**/
let mapStateToProps = (state) => {
    return {
        user: state.userState
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        IsAuthDispatch: (bool) => {
            dispatch(IsAuthCreator(bool))
        }, AddUserDispatch: (user) => {
            dispatch(AddUserCreator(user))
        }
    }
}

function MainPage(props) {

    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);


    const handleClose = () => {
        Cookies.set('cookieNotification', true, { expires: 30 })

        setOpen(false);
    };
    // Cookies.set('cookieNotification', false, { expires: 30 })


    useEffect(() => {
        let CookieNotification = Cookies.get('cookieNotification')
        //console.log('load')
        if (CookieNotification === 'false' || CookieNotification === undefined) {
            setOpen(true)
        }
    },[])

    return (
        <div className={styles.Wrapper}>
            <div className={styles.Navbar}><NavBarContainer/></div>
            <div className={styles.Column}>
                {(width < 1200) ?
                    null
                    :
                    <div className={styles.wrapperSubNav}>
                        <SubNav/>
                    </div>
                }
                <div className={styles.Content}><MainRouter/></div>
            </div>
            {(width < 1200) ?
                <div  className={styles.Footer}><Footer/></div>
                :
                null
            }
            <Snackbar
                open={open}
                //autoHideDuration={5000}
                //onClose={handleClose}
                message="Мы используем Cookies, что бы всё работало корректно. =)"
                //style={{backgroundColor: '#2c6866'}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                action={
                    <React.Fragment>
                        <button className={styles.NotificationButton}  onClick={handleClose}>
                            Понятно
                        </button>
                    </React.Fragment>
                }
            />
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);