import React from 'react';
import styles from './Home.module.css'
import {useNavigate, Navigate } from "react-router-dom";
/**/
const Home = () => {
    let navigate = useNavigate();

    return <Navigate to="/tasks" replace={true} />

    /*return (
        <div className={styles.Wrapper}>
            <div className={styles.OneBlockWrapper}>
                <div className={styles.TasksWrapper}>
                    <div className={styles.Title}>Задачи</div>
                </div>
                <div className={styles.ProjectsWrapper}>
                    <div className={styles.Title}>Проекты</div>
                </div>
            </div>
        </div>
    );*/
};

export default Home;