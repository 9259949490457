import React from 'react';
import styles from "./Start.module.css"
import { useNavigate } from "react-router-dom";


const Start = (props) => {
    let navigate = useNavigate();

    return (
        <div className={styles.Wrapper}>
            <div className={styles.WrapperOneBlock}>
                <div className={styles.WrapperTitleOneBlock}>
                    <div className={styles.OneBlockTitle2}>
                        Освободите свой мозг от расходования
                        ресурсов на запоминания всех задач и планов.
                    </div>
                </div>

            <div className={styles.WrapperImageOneBlock}>

                <div className={`${styles.DayTaskDemo} ${styles.AnimImg}`}>
                    <div className={styles.TaskTimeDemo}>22ч</div>
                    Мотивация на день
                </div>
                <div className={`${styles.DayTaskDemo} ${styles.AnimImg2}`}>
                    <div className={styles.TaskTimeDemo}>19ч</div>
                    Рассказать друзьям про Biz Machine
                </div>
                <div className={`${styles.DayTaskDemo} ${styles.AnimImg3}`}>
                    <div className={styles.TaskTimeDemo}>12ч</div>
                    Начать откладывать на мечту
                </div>
                <div className={`${styles.DayTaskDemo} ${styles.AnimImg4}`}>
                    <div className={styles.TaskTimeDemo}>7ч</div>
                    Заполнить отчёт
                </div>
                <div className={`${styles.DayTaskDemoCompleted} ${styles.AnimImg5}`}>
                    Не пропустить курсы иностранного языка
                </div>
                <div className={`${styles.DayTaskDemo} ${styles.AnimImg6}`}>
                    <div className={styles.TaskTimeDemo}>18ч</div>
                    Посетить станцию тех обслуживания
                </div>
                <div className={`${styles.DayTaskDemoCompleted} ${styles.AnimImg7}`}>
                    Напомнить коллегам про новый график
                </div>


            </div>
            </div>

            <div className={styles.WrapperButtonBlock}>
                <button className={styles.Button}
                        onClick={() =>
                            navigate('/login')} >
                    Начать
                </button>
                {/*<button className={styles.Button} onClick={() => navigate("/task")} >Регистрация</button>*/}
            </div>
        </div>
    )
}

export default Start;

