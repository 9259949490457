import React, {useEffect, useState} from 'react';
import styles from './WeekTasks.module.css'
import {DateFormatEngines} from '../../../Engines/DateFormatEngines'
import ClearIcon from '@mui/icons-material/Clear';
import {IconButton} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {changeStatusWeekTask, deleteWeekTask, fetchAllWeekTasks, timeIsOverWeekTask} from "../../../http/taskApi";

const WeekTaskItem = (props) => {

    //console.log(props)

    const [days, setDays] = useState(7 - Math.trunc(((new Date().getTime() - Number(props.startDate)) / (1000 * 60 * 60 * 24) % 30)));
    //const [days, setDays] = useState(1);

    const Success = async () => {

        if (props.status !== false) {
            await changeStatusWeekTask(props.id, props.userId)//.then((data) => console.log('status changed ' + data))
            await fetchAllWeekTasks(props.userId).then((data) => props.AddWeekTaskDispatch(data))
        }

    }

    // Асинхронно проверяем вышло ли заданное время и отправляем запрос на сервер timeIsOver: false,
    // после изменения в базе timeIsOver на false, заного фетчим таски
    useEffect(() => {
        if (props.status !== false && props.timeIsOver !== true) {
            if (new Date().getTime() >= Number(props.startDate) + 604801351) {
                //console.log('Time Is Over ' + props.id)
                timeIsOverWeekTask(props.id, props.userId).then((data) => data)
                fetchAllWeekTasks(props.userId).then((data) => props.AddWeekTaskDispatch(data))
            } else {
                //console.log('Time Is OK ' + props.id)
            }
        }
    }, [])

    const ThemeTask = () => {
        if (props.status === false) {
            return styles.WeekTaskItemCompleted
        } else if (props.status === true) {
            return styles.WeekTaskItem
        }
    }

    const ThemeTaskTime = () => {
        if (days <= 7 && days > 1) {
            return {color: '#9a9a9a', position: "absolute", left: '5px', fontWeight: 450, fontSize: '16px'}
        }  else if (days <= 1) {
            return {color: 'rgba(231,12,12,0.87)', position: "absolute", left: '5px', fontWeight: 400, fontSize: '14px'}
        }
    }

    return (
        <div>
            {(props.timeIsOver === false) ?
                <div className={styles.WeekOneTaskWrapper}>
                    <div className={ThemeTask()} onClick={() => Success()}>
                        {(props.status === true) ?
                            <div style={ThemeTaskTime()}>
                                {days}д
                            </div> : null}
                        {props.task}
                    </div>
                    <IconButton onClick={() => props.Delete(props.id)}><ClearIcon/></IconButton>
                </div>
                :
                <div className={styles.WeekOneTaskWrapper}>
                    <div className={styles.WeekTaskItemTimeIsOver}>
                        <AccessTimeIcon style={{color: '#a12121', position: "absolute", left: '5px'}}/>
                        {props.task}
                    </div>
                    <IconButton onClick={() => props.Delete(props.id)}><ClearIcon/></IconButton>
                </div>
            }

        </div>
    );
};

export default WeekTaskItem;





            /*//let Array = ['apple', 'orange', 'kiwi']
            Array.push('potato')
            console.log(Array)





            let Array = ['apple', 'orange', 'kiwi']
            Array.unshift('potato')
            console.log(Array)*/