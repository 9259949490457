import React from 'react';
import {connect} from "react-redux";
import {AddUserCreator, IsAuthCreator} from "../../store/user-reducer";
import ProductivityCalendar from "./ProductivityCalendar";
import {MomentDateCreator, SetDataCreator} from "../../store/productivity-reducer";



let mapStateToProps = (state) => {
    return {
        productivityState: state.productivityState,


    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        SetDataDispatch: (data) => {
            //console.log(props)
            dispatch(SetDataCreator(data))
        }, MomentDateDispatch: (data) => {
            //console.log(props)
            dispatch(MomentDateCreator(data))
        }
    }
}

const ProductivityCalendarContainer = connect(mapStateToProps, mapDispatchToProps)(ProductivityCalendar);

export default ProductivityCalendarContainer;